import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '../../shared/components/MainLoader';

// TODO add loading component
export const Logout: React.FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  return <Loader>Loging out . . .</Loader>;
};
