import { createAction } from '@cobuildlab/react-simple-state';
import { ApolloClient } from '@apollo/client';
import { Company, CompanyUpdateInput } from '@cobuildlab/salezio-shared';
import { apolloClient } from '../../shared/apollo';
import {
  OnSubscriptionPlansListEvent,
  OnSubscriptionPlansListEventError,
  OnCompanySelect,
  OnCompanySelectError,
  OnCompanyUpdated,
  OnCompanyUpdatedError,
  OnFetchCompany,
  OnFetchCompanyError,
} from './company-events';
import {
  SUBSCRIPTION_PLANS_QUERY,
  SELECT_COMPANY_MUTATION,
  UPDATE_COMPANY_MUTATION,
  FETCH_COMPANY_FOR_BILLING_STATUS,
} from './company-queries';
import { transformToCompanyUpdateInput } from './compay-utils';

type PlanFilter = {
  isFreeTrial: {
    equals: boolean;
  };
};

/**
 * Created a filter object for subscription plans.
 *
 * @param data - The data used to filter the results.
 * @param data.isFreeTrial - Free trial parameter.
 *
 * @returns The filter object.
 */
export const createSubscriptionPlansFilter = (data: {
  isFreeTrial?: boolean;
}): PlanFilter => {
  const filter: PlanFilter = {
    isFreeTrial: {
      equals: false,
    },
  };

  if (data.isFreeTrial !== undefined)
    filter.isFreeTrial = { equals: data.isFreeTrial };

  return filter;
};

/**
 * Fetches the subscription plans for the company.
 *
 * @param client - ApolloClient.
 * @param filters - The filter object.
 * @param filters.isFreeTrial - .
 *
 * @returns The data.
 */
export const fetchSubscriptionPlans = async (
  client: ApolloClient<unknown>,
  filters: {
    isFreeTrial?: boolean;
  } = {},
): Promise<unknown> => {
  const filter = createSubscriptionPlansFilter(filters);
  let response = null;

  try {
    response = await client.query({
      query: SUBSCRIPTION_PLANS_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchSubscriptionPlans: ', e);

    return OnSubscriptionPlansListEventError.dispatch(e);
  }
  console.log('fetchSubscriptionPlans', response.data);

  OnSubscriptionPlansListEvent.dispatch(response.data);

  return response.data;
};

/**
 * Select company action.
 *
 * @param {string} user - User id.
 * @param {string} company - Company id.
 * @returns {Promise<string>} - Selected company id.
 */
export const selectCompany = createAction(
  OnCompanySelect,
  OnCompanySelectError,
  async (user: string, company: string): Promise<string> => {
    const { data } = await apolloClient.mutate<{
      userUpdate: {
        selectedCompany: {
          id: string;
        };
      };
    }>({
      mutation: SELECT_COMPANY_MUTATION,
      variables: { user, company },
    });

    return data?.userUpdate.selectedCompany.id as string;
  },
);

/**
 * Update company action.
 *
 * @param {Company} company - Company Object.
 * @returns {Promise<Company>} - Selected company id.
 */
export const updateCompany = createAction(
  OnCompanyUpdated,
  OnCompanyUpdatedError,
  async (company: Company): Promise<Company> => {
    const variables: { data: CompanyUpdateInput } = {
      data: transformToCompanyUpdateInput(company),
    };
    const { data } = await apolloClient.query<{ companyUpdate: Company }>({
      query: UPDATE_COMPANY_MUTATION,
      variables,
      fetchPolicy: 'no-cache',
    });

    return data.companyUpdate;
  },
);

/**
 * Update company action.
 *
 * @param {string} company - Company id.
 * @returns {Promise<Company>} -  Company Object.
 */
export const fetchCompany = createAction(
  OnFetchCompany,
  OnFetchCompanyError,
  async (company: string): Promise<Company> => {
    const variables: { id: string } = {
      id: company,
    };
    const { data } = await apolloClient.query<{ company: Company }>({
      query: FETCH_COMPANY_FOR_BILLING_STATUS,
      variables,
      fetchPolicy: 'no-cache',
    });

    return data.company;
  },
);
