import { createEvent } from '@cobuildlab/react-simple-state';
import { Company, Plan } from '@cobuildlab/salezio-shared';

/**
 * Event that triggers when Companies are fetched.
 */
export const OnCompanyListFetch = createEvent<string>();

/**
 * Event that triggers when an Company is fetched.
 */
export const OnCompanyFetched = createEvent<string>();

/**
 * Event that triggers when an Company is updated.
 */
export const OnCompanyUpdated = createEvent<Company>();

export const OnCompanyUpdatedError = createEvent<Error>();

export const OnFetchCompany = createEvent<Company>();

export const OnFetchCompanyError = createEvent<Error>();
/**
 * Event that triggers a subscription plans list.
 */
export const OnSubscriptionPlansListEvent = createEvent<{
  plansList: {
    count: number;
    items: Plan[];
  };
}>();

/**
 * Event that triggers a subscription plans list error.
 */
export const OnSubscriptionPlansListEventError = createEvent<string>();

/**
 * Event that triggers a company error.
 */
export const OnCompanyErrorEvent = createEvent<string>();

export const OnCompanySelect = createEvent<string>();
export const OnCompanySelectError = createEvent<Error>();
