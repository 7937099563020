import React, { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from '../../shared/components/ui/Loader';

/**
 * Salesforce callback url page
 * @constructor
 */
export const SalesForceView: React.FC = () => {
  const path = useRef(window.location.href);
  useEffect(() => {
    const url = new URL(path.current);
    const code = url.searchParams.get('code');
    const opener = window.opener as any;
    if (opener && opener.salesforceConnected) {
      opener.salesforceConnected(code); // salesforceConnected is set in initializeSalesforce function
    }
    window.close();
  }, []);

  return (
    <div className="LoaderMain">
      <Loader />
      <Redirect to="/" />
    </div>
  );
};
