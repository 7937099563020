import { BoostProvider } from '@cobuildlab/boost';
import { salezioTheme } from './theme';

/**
 * Provider Theme.
 *
 * @param {object}props - Props.
 * @param {JSX.Element} props.children - Children.
 * @returns {JSX.Element} - Component.
 */
function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <BoostProvider theme={salezioTheme}>{children}</BoostProvider>;
}

// eslint-disable-next-line import/no-default-export
export default ThemeProvider;
