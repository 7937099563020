import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { ReactElement } from 'react';
import { useBreadcrumb } from '../../shared/components/breabcrumb/breabcrumb-hooks';
import { RoleAuthorization } from '../../shared/components/RoleAuthorization';
import { RoutesKeys } from '../../shared/constants/menu-routes';
import { TeamManagementLayout } from './components/TeamManagementLayout';

/**
 *  Dashoard View for App.
 *
 * @param {React.ReactElement} children - Children of View.
 * @returns {React.ReactElement} - React element.
 */
// eslint-disable-next-line import/no-default-export
export default function TeamManagementView({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  useBreadcrumb(RoutesKeys.TEAM_MANAGEMENT);
  return (
    <RoleAuthorization permission={PermissionsNames.TEAM_MANAGEMENT_VIEW}>
      <TeamManagementLayout>{children}</TeamManagementLayout>
    </RoleAuthorization>
  );
}
