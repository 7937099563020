import { createEvent } from '@cobuildlab/react-simple-state';
import { SalesforceConnectedEventType, SalesforceLogRecord, SalesforceMappingType} from './salesforce-types';


export const salesforceConnectedEvent = createEvent<SalesforceConnectedEventType | null>();
export const salesforceConnectingEvent = createEvent<boolean>({ initialValue: false });
export const salesforceConnectedError = createEvent<Error>();

export const salesforceConnectionCreatedEvent = createEvent<string>();
export const salesforceConnectionCreatedError = createEvent<Error>();
export const salesforceConnectionValidationEvent = createEvent<boolean>({ initialValue: true });

export const salesforceLogsEvent = createEvent<SalesforceLogRecord[]>();
export const salesforceLogsError = createEvent<Error>();

export const salesforceUpdateNow = createEvent<boolean>();
export const salesforceUpdateNowEvent = createEvent<{ success: boolean }>();
export const salesforceUpdateNowErrorEvent = createEvent<Error>();


export const salesforceFieldMappingCreateEvent = createEvent<SalesforceMappingType>();
export const salesforceFieldMappingCreateErrorEvent = createEvent<Error>();

export const salesforceFieldMappingDeleteEvent = createEvent<{ success: string }>();
export const salesforceFieldMappingDeleteErrorEvent = createEvent<Error>();
export const salesforceFieldMappingUpdateEvent = createEvent<SalesforceMappingType>();
export const salesforceFieldMappingUpdateErrorEvent = createEvent<Error>();

export const salesforceFieldMappingsEvent = createEvent<SalesforceMappingType[]>();
export const salesforceFieldMappingsErrorEvent = createEvent<Error>();

export const salesforceMissingFieldsEvent = createEvent<string>();

export const salesforceObjectFieldsEvent = createEvent<{ opportunity: string[] }>();
export const salesforceObjectFieldsErrorEvent = createEvent<Error>();
