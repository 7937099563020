import { css } from 'styled-components';

export const customScrollBar = css`
  --scrollbarBG: #f1f1f1;
  --scrollbarBGActive: #d4d4d4;
  --thumbBG: #ccc;
  --thumbBGHover: #b3b3b3;
  --thumbBGActive: #888888;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: 9px; /* Tamaño del scroll en vertical */
    height: 9px; /* Tamaño del scroll en horizontal */
  }
  &:not(:hover)::-webkit-scrollbar-thumb,
  &:not(:hover)::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: 0 0 2px 1px transparent;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    transition: all 1s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--thumbBG);
    border-radius: 10px;
  }
  &:hover::-webkit-scrollbar-thumb:hover {
    background: var(--thumbBGHover);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:active {
    background: var(--thumbBGActive);
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background: var(--scrollbarBGActive);
  }
`;
