import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useEvent, useSubscription } from '@cobuildlab/react-simple-state';
import { handleAuthentication, resendEmail } from './auth-callback-actions';
import {
  OnHandleAuth,
  OnResendVerificationEmail,
} from './auth-callback-events';
import { Loader } from '../../../shared/components/MainLoader';
import { YesNoDialog } from '../../../shared/components/YesNoDialog';
import { OnToken } from '../auth-events';

// TODO add loading component
// TODO this view could be omitted by using Auth0 hooks to create the user in 8base after signup, instead of doing here.
export const AuthCallback: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const client = useApolloClient();
  const token = useEvent(OnToken);
  const auth = useAuth0();
  const { user, isAuthenticated, error } = auth;

  useEffect(() => {
    if (isAuthenticated && token) {
      const invited = localStorage.getItem('invited') === 'true';
      handleAuthentication(client, user, invited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token]);
  useSubscription(OnHandleAuth, () => {
    history.replace('/');
  });
  useSubscription(OnResendVerificationEmail, (data) => {
    toast.success(data);
  });

  if (error) {
    const handleClose = (): void => {
      history.push('/logout');
    };
    const isBlocked = error.message.includes('block');
    const params = new URLSearchParams(String(location.search).substring(1));
    const userId = params?.get('error_description')?.split(':')[1];
    return (
      <YesNoDialog
        onYes={handleClose}
        onClose={handleClose}
        onNo={() => {
          if (!isBlocked) resendEmail(userId ?? '');
          else {
            handleClose();
          }
        }}
        title={`${isBlocked ? 'User Blocked' : 'Verify Email'}`}
        text={
          isBlocked
            ? error.message
            : 'An email has been sent to verify your email. Please verify your inbox and access the verification link before logging in.'
        }
        isOpen
        yesText="Ok"
        type="primary"
        noText={isBlocked ? 'Exit' : 'Resend Confirmation Email'}
      />
    );
  }
  // NOT ADD LOADING COMPONENT HERE this must be a loading auth component
  return <Loader>Loading user . . .</Loader>;
};
