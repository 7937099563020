import { createEvent } from '@cobuildlab/react-simple-state';
import {
  InvitationListResponse,
  InvitationAdminListResponse,
  Company,
  User,
} from '@cobuildlab/salezio-shared';

export const OnFetchInvitations = createEvent<InvitationListResponse>();
export const OnFetchInvitationsError = createEvent<Error>();
export const OnFetchInvitationAdmins = createEvent<InvitationAdminListResponse>();
export const OnFetchInvitationAdminsError = createEvent<Error>();
export const OnAcceptInvitation = createEvent<boolean>();
export const OnAcceptInvitationError = createEvent<Error>();
export const OnRejectInvitation = createEvent<string>();
export const OnRejectInvitationError = createEvent<Error>();
export const OnInvitationLoading = createEvent<boolean>({ initialValue: true });

export const onCompanyDataForInvitations = createEvent<Company>();
export const onCompanyDataForInvitationsError = createEvent<Error>();

export const onCreateAllInvitation = createEvent<boolean>();
export const onCreateAllInvitationError = createEvent<Error>();

export const onUpdateUserInvitations = createEvent<User>();
export const onUpdateUserInvitationsError = createEvent<Error>();
