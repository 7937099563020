import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { clearWindowCache } from '../../utils/cache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetry = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> =>lazy(async () => {
  try {
    return await factory();
  } catch (error) {
    // try to clear cache and reload page
    if (await clearWindowCache()) {
      window.location.reload();
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error;
  }
});
