import React from 'react';
import styled from 'styled-components';
import { customScrollBar } from '../css/scrollbar';
import { Breabcrumb } from './breabcrumb/Breabcrumb';
import { Loader } from './MainLoader';
import { Menu } from './Menu';
import { Topbar } from './Topbar';
// import { Loader } from './ui/Loader';

const Container = styled.div`
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 56px 32px 1fr;
  grid-template-areas: 'menu topbar' 'menu breadcrumb' 'menu contents';
  width: 100%;
  max-height: 100vh;
`;
const LayoutContent = styled.main`
  grid-area: contents;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  ${customScrollBar}
`;

/**
 * Layout component.
 * Default shows a skeleton.
 *
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - Children component to be rendered.
 * @param {boolean} props.loading - Loadin to know when to show the content.
 * @returns {JSX.Element} - Layout component.
 */
export function Layout({
  children,
  loading = false,
}: {
  children: React.ReactNode;
  loading?: boolean;
}): JSX.Element {
  // for now put a loader but a skaeleton should be
  return (
    <Container data-testid="layout-container">
      <Topbar />
      <Breabcrumb />
      <Menu />
      <LayoutContent>{loading ? <Loader /> : children}</LayoutContent>
    </Container>
  );
}
