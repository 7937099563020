import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../constants/colors';

const colorsAndText = {
  1: { textColor: THEME_COLORS.PRIMARY, bg: 'rgba(61,180,170,0.1)' },
  2: { textColor: '#F6C01E', bg: 'rgba(246,192,30,0.15)' },
  3: { textColor: '#eb7734', bg: 'rgba(235, 119, 52, 0.1)' },
  4: { textColor: '#FF0000', bg: 'rgba(255,0,0,0.08)' },
  5: { textColor: '#4D555F', bg: 'rgba(30, 67, 88,0.08)' },
};

const Badge = styled.span<{ textColor: string; bg: string }>`
  opacity: 0.9;
  color: ${({ textColor }) => textColor};
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  box-sizing: border-box;
  height: 26px;
  min-width: 110px;
  border: 1px solid ${({ textColor }) => textColor};
  border-radius: 16px;
  background-color: ${({ bg }) => bg};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type StyledBadgeProps = {
  children: React.ReactNode;
  priority?: 1 | 2 | 3 | 4 | 5;
  customPriority?: { textColor: string; bg: string };
};

export const StyledBadge = ({
  children,
  priority = 1,
  customPriority,
}: StyledBadgeProps): JSX.Element => {
  const colors = customPriority || colorsAndText[priority];
  return (
    <Badge textColor={colors.textColor} bg={colors.bg}>
      <span>{children}</span>
    </Badge>
  );
};
