export const SALESFORCE_CUSTOM_FIELD_LABEL = 'salezio_custom';

export const SCHEDULE_TIME_OPTIONS = [
  { label: 'Never', value: '0' },
  { label: 'Every Day', value: '1' },
  { label: 'Every 2 Days', value: '2' },
  { label: 'Every 3 Days', value: '3' },
  { label: 'Every 4 Days', value: '4' },
  { label: 'Every 5 Days', value: '5' },
  { label: 'Every 6 Days', value: '6' },
  { label: 'Every Week', value: '7' },
];

export const SALESFORCE_FIELD_MAPPING_OBJECTS = [
  { value: 'opportunity', label: 'Opportunity' },
];

export const SALESFORCE_CONNECTION_STATUS_ACTIVE = 'ACTIVE';
export const SALESFORCE_CONNECTION_STATUS_INACTIVE = 'INACTIVE';

export const SALESFORCE_OPPORTUNITY_REMOVE_FIELDS = [
  'AccountId',
  'CampaignId',
  'CurrentGenerators__c',
  'DeliveryInstallationStatus__c',
  'Fiscal',
  'FiscalQuarter',
  'FiscalYear',
  'ForecastCategory',
  'ForecastCategoryName',
  'HasOpenActivity',
  'HasOpportunityLineItem',
  'HasOverdueTask',
  'IsClosed',
  'IsDeleted',
  'IsPrivate',
  'IsWon',
  'LastModifiedById',
  'LastReferencedDate',
  'LastViewedDate',
  'LeadSource',
  'MainCompetitors__c',
  'OwnerId',
  'Pricebook2Id',
  'Probability',
  'SystemModstamp',
  'TotalOpportunityQuantity',
];

/**
 * Deal fields used in SalesforceFieldMappingSettingCard.
 */
export const SALESFORCE_LEAD_FIELDS = [
  { id: 'customerName', label: 'Customer Name' },
  { id: 'customerPhone', label: 'Customer Phone' },
  { id: 'amount', label: 'Amount' },
  { id: 'leadSourceId', label: 'Lead Source ID' },
  { id: 'partnerRegistrationId', label: 'Partner Registration ID' },
  { id: 'closeDate', label: 'Close Date' },
  { id: 'lostReason', label: 'Lost Reason' },
  { id: 'wonReason', label: 'Won Reason' },
  { id: 'createdDate', label: 'Created Date' },
  { id: 'name', label: 'Deal Name' },
  { id: 'description', label: 'Deal Description' },
  { id: 'stage', label: 'Deal Stage' },
  { id: 'type', label: 'Deal Type' },
  { id: 'salesPerson', label: 'Sales Person' },
  { id: 'lastActivityDate', label: 'Last Activity Date' },
  { id: 'lastContactedDate', label: 'Last Contacted Date' },
  { id: 'lastModifiedDate', label: 'Last Modified Date' },
  { id: 'nextActivityDate', label: 'Next Activity Date' },
  { id: 'nextActivityDescription', label: 'Next Activity Description' },
  { id: 'numberOfSalesActivities', label: 'Number of Sales Activities' },
  { id: 'numberOfTimesContacted', label: 'Number of Times Contacted' },
  { id: 'line', label: 'Product Line' },
];
