export const THEME_COLORS = {
  PRIMARY: '#355d8b',
  PRIMARY_CTA: '#036ae2',
  PRIMARY_LIGHT: '#f2f6fc',
  PRIMARY_DARK: '#4e4e4e',
  DARK_SLATE: '#323c47',
  MEDIUM_SLATE: '#878c93',
  LIGHT_SLATE: '#d0d7dd',
  GRAY_30: '#CFD7DE',
  GRAY: '#9b9b9b',
  SECONDARY_TEXT_COLOR: '#5e6269',
  DARK: '#2b3238',
  DISABLED_TEXT_COLOR: '#a5afb6',
};
