import {
  InternalRoutesKeys,
  ROUTES,
  RoutesKeys,
} from '../../constants/menu-routes';
import { BreabrumbLink } from './breabcrumb-type';
/**
 *
 * @param {RoutesKeys|InternalRoutesKeys} routeKey - Key of search route.
 * @returns {string} Breabcrumb text.
 */
export function routeToBreabcrumb(
  routeKey: RoutesKeys | InternalRoutesKeys,
): BreabrumbLink[] {
  const link: BreabrumbLink[] = [
    {
      text: 'Salez.io',
      route: ROUTES.DASHBOARD.path,
    },
  ];
  Object.keys(ROUTES).forEach((key): void => {
    if (Object.prototype.hasOwnProperty.call(ROUTES, key)) {
      if (routeKey === key) {
        link.push({
          text: `${ROUTES[key as RoutesKeys].name}`,
          route: ROUTES[key as RoutesKeys].path,
        });
        return;
      }
      const element = ROUTES[key as RoutesKeys];
      if (element.internalPaths) {
        Object.keys(element.internalPaths).forEach((internalKey) => {
          if (
            element.internalPaths &&
            Object.prototype.hasOwnProperty.call(
              element.internalPaths,
              internalKey,
            )
          ) {
            if (routeKey === internalKey) {
              link.push({
                text: `${ROUTES[key as RoutesKeys].name}`,
                route: ROUTES[key as RoutesKeys].path,
              });
              link.push({
                text: `${
                  element.internalPaths[internalKey as InternalRoutesKeys]?.name
                }`,
                route:
                  element.internalPaths[internalKey as InternalRoutesKeys]
                    ?.path ?? '',
              });
            }
          }
        });
      }
    }
  });
  return link;
}
