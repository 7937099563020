import styled from 'styled-components';
import { SiteTitle } from './SiteTitle';
// import { UnStyledButton } from './ui/buttons/Buttons';
// import { Icon } from '../icons/Icon';
// import { THEME_COLORS } from '../constants/colors';
import { UserProfile } from './UserProfile';
import { CompanySwitcher } from './CompanySwitcher';
import { Notifications } from '../../modules/notifications/Notifications';

const Container = styled.header`
  grid-area: topbar;
  width: calc(100%);
  margin-left: 56px;
  background-color: white;
  border-bottom: 1px solid #e9eff4;
  margin: 0;
  display: grid;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 24px;
  align-items: center;
`;

const IconsContainer = styled.nav`
  justify-self: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 615px) {
    justify-content: center;
  }
  & > * {
    margin: 0px 20px;
    width: 20px;
    display: flex;
  }
`;

/**
 * Main topbar component.
 *
 * @returns {JSX.Element} Topbar component.
 */
export function Topbar(): JSX.Element {
  return (
    <Container>
      <SiteTitle />
      <CompanySwitcher />
      <IconsContainer aria-label="quick actions">
        {/* <UnStyledButton aria-label="invitations">
          <Icon
            icon="invitations"
            size={22}
            hoverColor={THEME_COLORS.PRIMARY_CTA}
          />
        </UnStyledButton> */}
        <Notifications />
        {/* <UnStyledButton aria-label="chat">
          <Icon icon="chat" size={22} hoverColor={THEME_COLORS.PRIMARY_CTA} />
        </UnStyledButton> */}
        <UserProfile />
      </IconsContainer>
    </Container>
  );
}
