import React, { useState, useCallback } from 'react';
import { createEvent, useEvent } from '@cobuildlab/react-simple-state';
import { Dialog, Grid, Button, Text } from '@cobuildlab/boost';
import styled from 'styled-components';
import { clearWindowCache } from '../../utils/cache';

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const OnDialogChange = createEvent<boolean>();

export const AppUpdateDialog: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const open = useEvent(OnDialogChange);

  const onRefresh = useCallback(async () => {
    setLoading(true);
    await clearWindowCache();
    window.location.reload();
  }, []);

  const onCancel = useCallback(() => {
    OnDialogChange.dispatch(false);
  }, []);

  return (
    <Dialog isOpen={open}>
      <Dialog.Header title="New update" onClose={onCancel} />
      <Dialog.Body scrollable>
        <Grid.Layout gap="sm" stretch>
          <Grid.Box>
            <Text>
              There is a new update available for the app. If you want to get
              the newest version, please close all other tabs on this site and
              refresh this page
            </Text>
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Body>
      <Dialog.Footer>
        <FlexBox>
          <div style={{ marginRight: '15px' }}>
            <Button disabled={loading} onClick={onCancel}>
              CANCEL
            </Button>
          </div>
          <Button color="primary" disabled={loading} onClick={onRefresh}>
            REFRESH
          </Button>
        </FlexBox>
      </Dialog.Footer>
    </Dialog>
  );
};
