import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { RouteProps, Route } from 'react-router-dom';
import { useRoleAuth } from '../shared/utils/hooks';

interface RoleRouteProps extends RouteProps {
  permission?: PermissionsNames;
  loading?: boolean;
  error?: ((message: string) => React.ReactNode) | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: Record<any, any>;
}
/**
 * @param {object} props - Props.
 * @param {JSX.Element} props.children - Children.
 * @param {boolean} props.loading - Loading to wait before make any request.
 * @param {PermissionsNames} props.permission - Permission to eject.
 * @param {JSX.Element} props.error - Opcional render.
 * @param {Record<any, any>} props.extraData - Additional data for permission.
 * @returns {JSX.Element} Props.children - props.
 * @example
 *
 * return (
 *   <RoleRoute loading={loadingSession} permission={PermissionsNames.DASHBOARD_VIEW}>
 *    {ChildrenComponent}
 *   </RoleRoute>
 * )
 * or...
 * return (
 *   <RoleRoute loading={loadingSession} permission={PermissionsNames.DASHBOARD_VIEW}>
 *    (loading)=> loading ? <Loader /> : {ChildrenComponent}
 *   </RoleRoute>
 * )
 * or...
 * return (
 *   <RoleRoute loading={loadingSession} permission={PermissionsNames.DASHBOARD_VIEW}
 *    error={ChildrenErrorComponent}>
 *    (loading)=> loading ? <Loader /> {ChildrenComponent}
 *   </RoleRoute>
 * <RoleRoute loading={loadingSession} render={(routeProps)=><ChildrenComponent {...routeProps}/>} permission={PermissionsNames.DASHBOARD_VIEW}/>
>
 * )
 *
 */
export function RoleRoute({
  // children,
  error,
  extraData,
  loading = false,
  permission = PermissionsNames.DASHBOARD_VIEW,
  ...rest
}: RoleRouteProps): JSX.Element {
  const [canRender, message] = useRoleAuth(permission, extraData, loading);

  const renderError = typeof error === 'function' ? error(message) : error;

  if (canRender) {
    return <Route {...rest} />;
  }

  return <Route {...rest}>{renderError}</Route>;
}
