import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { createSubscription } from '../../shared/apollo/apollo-utils';
import { OnSession, OnSessionError } from './session-events';
import {
  COMPANY_USER_SUBSCRIPTION,
  SESSION_QUERY,
  SESSION_SUBSCRIPTION,
} from './session-queries';
import {
  CompanyUserSubscriptionType,
  SessionSubscription as SessionSubscriptionType,
  UserSession,
} from './session-types';

/**
 * @returns {Promise<{ user: UserSession }>} User sesion data.
 */
export const fetchSession = createAction(
  OnSession,
  OnSessionError,
  async () => {
    const { data } = await apolloClient.query<{ user: UserSession }>({
      query: SESSION_QUERY,
      fetchPolicy: 'network-only',
    });
    return data.user;
  },
);

export const sessionSubscription = createSubscription<
  SessionSubscriptionType,
  { user: string }
>(SESSION_SUBSCRIPTION);

export const companyUserSubscription = createSubscription<
  CompanyUserSubscriptionType,
  { user: string }
>(COMPANY_USER_SUBSCRIPTION);
