import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Heading,
  Text,
  Tooltip,
  Avatar,
  Button,
  Icon,
} from '@cobuildlab/boost';
import { Notification } from '@cobuildlab/salezio-shared';
import moment from 'moment';
import styled from 'styled-components';
import { StyledBadge } from '../../../shared/components/ui/StyledBadge';
import { getNotificationInfo } from '../notifications-utils';
import { updateNotification } from '../notifications-actions';

const Link = styled(RouterLink)`
  text-decoration: none;
`;

const Container = styled(Grid.Layout)`
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
  &:not(:hover) {
    .notification-btn__delete {
      display: none;
    }
  }
  &:hover {
    background-color: #f6f7f9;
  }
`;

type NotificationsListItemProps = {
  data: Notification;
  onChange: (action: string, isDeleted: boolean, id: string) => void;
  toggle: () => void;
};

/**
 * @param {object} props - Props.
 * @param {Notification} props.data - Notification data.
 * @param {Function} props.onChange - Notification data.
 * @param {string} props.toggle - Mark as read.
 * @returns {JSX.Element} - React Component.
 */
export function NotificationsListItem({
  data,
  onChange,
  toggle,
}: NotificationsListItemProps): JSX.Element {
  const { id, createdAt, isDeleted } = data;
  const timeFromNow = moment(createdAt).fromNow();
  const formatedDate = moment(createdAt).format('MM/DD/YYYY; hh:mmA');

  const { title, description, url, linkText, avatar } =
    getNotificationInfo(data) ?? {};

  return (
    <Container
      columns="50px 1fr auto"
      gap="xs"
      areas={[
        ['avatar', 'title', 'actions'],
        ['avatar', 'body', 'body'],
        ['avatar', 'time', 'type'],
      ]}
    >
      <Grid.Box area="avatar">
        <Avatar
          size="sm"
          src={
            avatar ||
            'https://images.unsplash.com/photo-1533282960533-51328aa49826?ixlib=rb-1.2.1&auto=format&fit=crop&w=1930&q=80'
          }
        />
      </Grid.Box>
      <Grid.Box area="title">
        <Heading type="h4">{title}</Heading>
      </Grid.Box>
      <Grid.Box area="body">{description}</Grid.Box>
      <Grid.Box area="time" justifySelf="start">
        <Tooltip message={formatedDate}>
          <Text color="TEXT_LIGHT_GRAY">{timeFromNow}</Text>
        </Tooltip>
      </Grid.Box>
      <Grid.Box area="type" justifySelf="end">
        <Link
          to={url ?? ''}
          onClick={(e) => {
            if (!url) {
              e.preventDefault();
            }
            toggle();
            updateNotification({ isReaded: true }, id ?? '');
          }}
        >
          <StyledBadge>{linkText}</StyledBadge>
        </Link>
      </Grid.Box>
      <Grid.Box
        direction="row"
        area="actions"
        justifySelf="end"
        justifyContent="end"
      >
        <Button
          className="notification-btn__delete"
          size="lg"
          color="neutral"
          variant="link"
          loading={isDeleted}
          onClick={() => {
            updateNotification({ isDeleted: true }, id ?? '');
            onChange('read', !isDeleted, id ?? '');
          }}
        >
          <Icon name="Delete" />
        </Button>
      </Grid.Box>
    </Container>
  );
}
