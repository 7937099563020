import { useAuth0 } from '@auth0/auth0-react';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchToken } from './auth-actions';
import { OnToken, OnTokenError } from './auth-events';

/**
 *
 * @returns {[boolean,boolean,string|Error]} - Returns loading Auth and loading Token.
 */
export function useSetupAuthToken(): [
  boolean,
  boolean,
  { token: string | null; error: Error | null },
] {
  const auth = useAuth0();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setLoading(true);
      fetchToken(auth);
    }
  }, [auth.isAuthenticated, auth]);

  useSubscription(OnToken, (data) => {
    setToken(data);
    setLoading(false);
  });
  useSubscription(OnTokenError, (data) => {
    setError(data);
    setLoading(false);
  });

  return [auth.isLoading, loading, { token, error }];
}
/**
 * @param {string} route - Default path to redirect.
 * @returns {void}
 */
export function useDefaultRedirect(route: string): void {
  const history = useHistory();
  const auth = useAuth0();

  useEffect(() => {
    if (auth.isAuthenticated && history.location.pathname === '/') {
      history.push(route);
    }
  }, [history, auth.isAuthenticated, route]);

  // TODO: hanlde the error case when fetching the token
}
