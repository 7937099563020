import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TeamManagementSideBar } from './TeamManagementSideBar';

const MainContainer = styled.div`
  display: flex;
  height: 100%;
`;

/**
 *  Dashoard View for App.
 *
 * @param {ReactElement} children - Children of View.
 * @returns {ReactElement} - React element.
 */
export function TeamManagementLayout({ children }: Props): ReactElement {
  return (
    <MainContainer>
      <TeamManagementSideBar />
      {children}
    </MainContainer>
  );
}
type Props = {
  children: ReactElement;
};
