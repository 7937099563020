import { gql } from '@apollo/client';

export const CompanyUserFragment = gql`
  fragment CompanyUserFragment on CompanyUser {
    id
    role
    disabled
    company {
      companyTeamRelation {
        items {
          id
        }
      }
      id
      name
      companySubscriptionHistoryRelation(sort: { createdAt: DESC }, first: 1) {
        items {
          status
          expireDate
        }
      }
    }
  }
`;
export const UserFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    cellPhone
    workPhone
    workPhoneExt
    timezone
    email
    shouldCreateCompany
    shouldSendInvitations
    avatar {
      downloadUrl
    }
    selectedCompany {
      id
      createdBy {
        id
      }
      companySubscriptionHistoryRelation(sort: { createdAt: DESC }, first: 1) {
        items {
          status
          expireDate
        }
      }
    }
    userTeamUserRelation(filter: { enable: { equals: true } }) {
      count
      items {
        id
        role
        enable
        team {
          id
          name
          company {
            id
            name
          }
        }
      }
    }
    userCompanyUserRelation {
      items {
        ...CompanyUserFragment
      }
    }
  }
  ${CompanyUserFragment}
`;
export const SESSION_QUERY = gql`
  query {
    user {
      ...UserFragment
    }
  }
  ${CompanyUserFragment}
  ${UserFragment}
`;

export const SESSION_SUBSCRIPTION = gql`
  subscription($user: ID!) {
    Users(filter: { mutation_in: update, node: { id: { equals: $user } } }) {
      mutation
      updatedFields
      node {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;
export const COMPANY_USER_SUBSCRIPTION = gql`
  subscription($user: ID!) {
    CompanyUser(
      filter: {
        mutation_in: [update, delete, create]
        node: { user: { id: { equals: $user } } }
      }
    ) {
      mutation
      node {
        ...CompanyUserFragment
      }
    }
  }
  ${CompanyUserFragment}
`;
