import { Redirect, RouteProps, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useSessionRedirects, useSetupSession } from './session-hooks';

interface SessionProps extends RouteProps {
  children: ((loading: boolean) => React.ReactNode) | React.ReactNode;
  loading?: boolean;
}
/**
 * @param {object} props - Props.
 * @param {JSX.Element} props.children - Children.
 * @param {boolean} props.loading - Loading to wait before make any request.
 * @returns {JSX.Element} Props.children - props.
 * @example
 *
 * return (
 *   <Session>
 *    <ChildrenComponent />
 *   </Session>
 * )
 * or...
 * return (
 *   <Session>
 *    (loading)=> loading ? <Loader /> : <ChildrenComponent />
 *   </Session>
 * )
 *
 */
export function Session({
  children,
  loading = false,
  ...rest
}: SessionProps): JSX.Element {
  // Fetch session and other stuff.
  const [session, loadingSession, error] = useSetupSession(loading);
  const { logout } = useAuth0();

  // Use reder props pattern to pass loading state to children, if is needed
  const render =
    typeof children === 'function' ? children(loadingSession) : children;

  const [shouldRender, redirectPath] = useSessionRedirects(
    session,
    loadingSession,
  );

  if (error) {
    logout({ returnTo: window.location.origin });
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        shouldRender ? (
          render
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
