import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '../../shared/components/MainLoader';

// TODO add loading component
export const Register: React.FC<RouteComponentProps> = ({ location }) => {
  const { loginWithRedirect } = useAuth0();
  if (location.search === '?invited=true')
    localStorage.setItem('invited', 'true');
  useEffect(() => {
    loginWithRedirect({ screen_hint: 'signUp', invited:true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};
