import { createEvent } from '@cobuildlab/react-simple-state';
import {
  EndOfDayForm,
  EndOfDayFormListResponse,
  EndOfDayReport,
  GoalListResponse,
  SuccessResponse,
  Team,
} from '@cobuildlab/salezio-shared';

export const OnCreateGoal = createEvent<SuccessResponse>();
export const OnCreateGoalError = createEvent<Error>();
export const OnFetchCompanyTeams = createEvent<Team[]>();
export const OnFetchCompanyTeamsError = createEvent<Error>();
export const OnFetchGoals = createEvent<GoalListResponse>();
export const OnFetchGoalsError = createEvent<Error>();
export const OnFetchEndOfDayForms = createEvent<EndOfDayFormListResponse>();
export const OnFetchEndOfDayFormsError = createEvent<Error>();
export const OnSaveEndOfDayReport = createEvent<EndOfDayReport>();
export const OnSaveEndOfDayReportError = createEvent<Error>();
export const OnFetchTeamEndOfDayForms = createEvent<EndOfDayForm[]>();
export const OnFetchTeamEndOfDayFormsError = createEvent<Error>();
export const OnFetchReports = createEvent<EndOfDayReport[]>();
export const OnFetchReportsError = createEvent<Error>();
