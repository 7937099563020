import { createEvent } from '@cobuildlab/react-simple-state';
import {
  Notification,
  NotificationManyResponse,
} from '@cobuildlab/salezio-shared';

export const onUpdateNotificationEvent = createEvent<Notification>();
export const onUpdateNotificationErrorEvent = createEvent<Error>();
export const onReadNotifications = createEvent<NotificationManyResponse>();
export const onReadNotificationsError = createEvent<Error>();

export const onUpdateManyNotificationsErrorEvent = createEvent<Error>();
