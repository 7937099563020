import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './shared/css/App.css';
import { ApolloProvider } from './shared/apollo/ApolloProvider';
import { ENV_VARS_SETUP_IS_READY } from './shared/constants';
import { Auth0Provider } from './modules/auth/Auth0Provider';
import { Routes } from './routes/routes';
import { Loader } from './shared/components/MainLoader';
import ThemeProvider from './shared/boost/ThemeProvider';
import { AppUpdateDialog, OnDialogChange } from './shared/components/AppUpdateDialog';
import * as serviceWorker from './serviceWorker';
import { lazyWithRetry } from './shared/components/LazyWithRetry';
import { initWindowToast } from './utils/toast';

const Component = lazyWithRetry(() => import('./shared/components/DefaulView'));


/**
 * Main app component.
 *
 * @returns {JSX.Element} - Main app component.
 */
const App = (): JSX.Element => (
  <BrowserRouter>
    <ThemeProvider>
      {
        ENV_VARS_SETUP_IS_READY
          ? (
            <Auth0Provider>
              <ApolloProvider>
                <Routes />
                <AppUpdateDialog />
              </ApolloProvider>
            </Auth0Provider>
          )
          : (
            <Suspense fallback={<Loader />}>
              <Component />
            </Suspense>
          )
      }
    </ThemeProvider>
    <ToastContainer
      position="bottom-left"
      autoClose={10000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  </BrowserRouter>
);


initWindowToast();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => OnDialogChange.dispatch(true),
});

// eslint-disable-next-line import/no-default-export
export default App;
