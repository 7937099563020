import React from 'react';
import { Button, ButtonProps } from '@cobuildlab/boost';
import styled from 'styled-components';
import { THEME_COLORS } from '../../../constants/colors';

const StyledBtn = styled(Button)`
  height: 40px;
  padding: 0 2em !important;
  border-radius: 4px;
  box-sizing: border-box;
  ${({ color }) => {
    switch (color) {
      case 'danger':
        return `
          border: 1px solid #ff0000 !important;
          background-color: #ff0000 !important;    
        `;
      case 'success':
        return `
          border: 1px solid #3db4aa !important;
          background-color: #3db4aa !important;    
        `;
      case 'primary':
        return `
          border: 1px solid ${THEME_COLORS.PRIMARY} !important;
          background-color: ${THEME_COLORS.PRIMARY} !important;    
        `;
      default:
        return `
          border: 1px solid #D0D7DD !important;
          background-color: #D0D7DD !important;    
        `;
    }
  }}
`;

export const ActionBtn: React.FC<ButtonProps> = ({
  text,
  onClick,
  children,
  ...rest
}) => (
  <StyledBtn onClick={onClick} {...rest}>
    {children || text}
  </StyledBtn>
);
