/**
 * Clears cache if it exists.
 *
 * @returns (boolean) Is cache existed and cleared.
 */
export async function clearWindowCache(): Promise<boolean> {
  if ('caches' in window) {
    const keys = await window.caches.keys();
    if (keys.length) {
      keys.forEach((name) => caches.delete(name));
      return true;
    }
  }
  return false;
}
