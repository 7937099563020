import { Loader as BoostLoader } from '@cobuildlab/boost';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
`;

export const Loader = ({ size = 'md' }: LoaderProps): JSX.Element => (
  <LoaderContainer>
    <BoostLoader size={size} color="PRIMARY" />
  </LoaderContainer>
);
type LoaderProps = {
  size?: 'md' | 'sm' | 'lg';
};
