import { createEvent } from '@cobuildlab/react-simple-state';
import {
  InvitationListResponse,
  Invitation,
  TeamListResponse,
  User,
  UserListResponse,
  SuccessResponse,
  Team,
  EndOfDayForm,
  EndOfDayFormListResponse,
  NoteForm,
  NoteFormListResponse,
  TeamUser,
  Company,
} from '@cobuildlab/salezio-shared';

export const OnFetchUsers = createEvent<UserListResponse>();
export const OnFetchUsersError = createEvent<Error>();
export const OnFetchMembers = createEvent<UserListResponse>();
export const OnFetchMembersError = createEvent<Error>();
export const OnFetchInvitationList = createEvent<InvitationListResponse>();
export const OnFetchInvitationListError = createEvent<Error>();
export const OnUpdateTeamUser = createEvent<User>();
export const OnUpdateTeamUserError = createEvent<Error>();
export const OnSendinvitation = createEvent<Invitation>();
export const OnSendinvitationError = createEvent<Error>();
export const OnDeleteinvitation = createEvent<Invitation>();
export const OnDeleteinvitationError = createEvent<Error>();
export const OnResendinvitation = createEvent<Invitation>();
export const OnResendinvitationError = createEvent<Error>();
export const OnFetchTeams = createEvent<TeamListResponse>();
export const OnFetchTeamsError = createEvent<Error>();
export const OnCompanyForms = createEvent<Company>();
export const OnCompanyFormsError = createEvent<Error>();
export const OnDeleteCompanyUser = createEvent<SuccessResponse>();
export const OnDeleteTeamUser = createEvent<SuccessResponse>();
export const OnDeleteCompanyUserError = createEvent<Error>();
export const OnDeleteTeamUserError = createEvent<Error>();
export const OnDeleteTeam = createEvent<SuccessResponse>();
export const OnDeleteTeamError = createEvent<Error>();
export const OnUpdateTeam = createEvent<Team>();
export const OnUpdateTeamError = createEvent<Error>();
export const OnCreateTeam = createEvent<Team>();
export const OnCreateTeamError = createEvent<Error>();
export const OnFetchEndOfDayForm = createEvent<EndOfDayFormListResponse>();
export const OnFetchEndOfDayFormError = createEvent<Error>();
export const OnCreatedEndOfDayForm = createEvent<EndOfDayForm>();
export const OnCreatedEndOfDayFormError = createEvent<Error>();
export const OnUpdatedEndOfDayForm = createEvent<EndOfDayForm>();
export const OnUpdatedEndOfDayFormError = createEvent<Error>();
export const OnDeleteEndOfDayForm = createEvent<SuccessResponse>();
export const OnDeleteEndOfDayFormError = createEvent<Error>();
export const OnDisableTeamUser = createEvent<TeamUser>();
export const OnDisableTeamUserError = createEvent<Error>();
export const OnFetchNoteForm = createEvent<NoteFormListResponse>();
export const OnFetchNoteFormError = createEvent<Error>();
export const OnCreatedNoteForm = createEvent<NoteForm>();
export const OnCreatedNoteFormError = createEvent<Error>();
export const OnUpdatedNoteForm = createEvent<NoteForm>();
export const OnUpdatedNoteFormError = createEvent<Error>();
export const OnDeleteNoteForm = createEvent<SuccessResponse>();
export const OnDeleteNoteFormError = createEvent<Error>();
export const OnFetchTeamStats = createEvent<any>(); // update shared
export const OnFetchTeamStatsError = createEvent<Error>();
export const OnFetchCompanyUsers = createEvent<readonly User[]>({
  initialValue: [],
});
export const OnFetchCompanyUsersError = createEvent<Error>();
