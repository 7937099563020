import { Button, Dialog, Tooltip } from '@cobuildlab/boost';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { onErrorMixin } from '../../../shared/mixins';
import { OnSession } from '../../session/session-events';
import { saveEndOfDayReport } from '../reports-actions';
import FormBody from './components/FormBody';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const BodyContainer = styled.div`
  display: flex;
  padding: 4px;
  min-height: 50vh;
`;

/** .
 * Prints Modal to fill end of day form component.
 *
 *  @param {boolean} isOpen - Signal for open modal .
 *  @callback onClose - Callback to close the modal .
 * @returns {JSX.Element} - EndOfDayForm component.
 */
const EndOfDayForm = ({ isOpen, onClose }: Props): JSX.Element => {
  const [disableSendReport, setDisableSendReport] = useState(true);
  const [form, setForm] = useState<any>({});
  const [teamUserIdICE, setTeamUserIdICE] = useState<string | undefined>('');
  const [formId, setFormId] = useState<string | undefined>('');
  const [formName, setFormName] = useState('');
  const session = useEvent(OnSession);

  const handleClose = (): void => {
    setDisableSendReport(true);
    setFormName('');
    setFormId('');
    setTeamUserIdICE('');
    onClose();
  };

  const [submitSaveEndOfDayReport, isSubmiting] = useCallAction(
    saveEndOfDayReport,
    {
      onCompleted: () => {
        toast.success('Report sent successfully');
        handleClose();
      },
      onError: onErrorMixin,
    },
  );

  const onSendReport = async (): Promise<void> => {
    if (!formId || !teamUserIdICE) {
      return;
    }
    submitSaveEndOfDayReport(teamUserIdICE, formId, form);
  };

  return (
    <Dialog
      size="md"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEscPress
      onClose={handleClose}
    >
      <Dialog.Header
        title={formName || 'End of Day reports'}
        onClose={handleClose}
      />
      <BodyContainer>
        <Dialog.Body>
          <FormBody
            companyUsers={session.userCompanyUserRelation}
            form={form}
            setDisableSendReport={setDisableSendReport}
            setForm={setForm}
            setTeamUserIdICE={setTeamUserIdICE}
            setFormId={setFormId}
            setFormName={setFormName}
          />
        </Dialog.Body>
      </BodyContainer>
      <Dialog.Footer>
        {disableSendReport ? (
          <Tooltip message="Fill the required fields before submit the form">
            <Button
              loading={isSubmiting}
              disabled={disableSendReport}
              onClick={onSendReport}
            >
              Send report
            </Button>
          </Tooltip>
        ) : (
          <Button
            loading={isSubmiting}
            disabled={disableSendReport}
            onClick={onSendReport}
          >
            Send report
          </Button>
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

/* eslint-disable-next-line import/no-default-export */
export default EndOfDayForm;
