import { Company, CompanyUpdateInput } from '@cobuildlab/salezio-shared';
/**
 * Transform Company Object to CompanyUpdateInput Object.
 *
 * @param {Company} company - Company Object.
 *
 * @returns {CompanyUpdateInput} Company Ready to Update.
 */
export function transformToCompanyUpdateInput(
  company: Company,
): CompanyUpdateInput {
  return {
    id: company.id,
    name: company.name,
    status: company.status,
    taxId: company.taxId,
  };
}
