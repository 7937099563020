import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { selectCompany } from '../../modules/company/company-actions';
import { useSession } from '../../modules/session/session-hooks';
import {
  Dropdown,
  DropdownWrap,
  DropdownOptionType,
} from './dropdowns/Dropdown';

const SwitcherContainer = styled(DropdownWrap)`
  justify-self: center;
  max-height: 36px;
`;
/**
 * Company to switch between companies.
 *
 * @returns {JSX.Element} - CompanySwitcher.
 */
export function CompanySwitcher(): JSX.Element {
  const session = useSession();
  const companies =
    session.companies.map(
      (company): DropdownOptionType => ({
        name: company.name,
        value: company.id,
        disabled: company.disabled,
        onDisabledClick: () => {
          toast.warning(
            // eslint-disable-next-line @typescript-eslint/quotes
            "This company is disabled, you can't access it. Please contact a company admin.",
          );
        },
      }),
    ) || [];
  const [current, setCurrent] = useState<string>(session?.selectedCompany.id);

  return (
    <SwitcherContainer>
      <Dropdown
        currentOption={current}
        options={companies}
        bodyHeader="Switch company"
        placheholder="Select company"
        showActiveOption
        offset="xl"
        onChange={(value) => {
          setCurrent(value as string);
          selectCompany(session.user.id, `${value}`);
        }}
      />
    </SwitcherContainer>
  );
}
