import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../constants/colors';
import { Icons } from './Icons';

interface IconProps {
  icon: keyof typeof Icons;
  color?: string;
  hoverColor?: string;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
}

type SvgProps = {
  fillColor?: string;
  hoverFillColor?: string;
  size?: number;
};

const Svg = styled.span<SvgProps>`
  display: inline-flex;
  align-items: center;
  transition: fill linear 0.4s;
  vertical-align: bottom;

  * {
    transition: inherit;
  }
  svg {
    ${(props) => (props.size ? `width: ${props.size}px;` : '')}
  }
  --fill-color: ${(props) => props.fillColor};
  &:hover {
    --fill-color: ${(props) => props.hoverFillColor};
  }
`;

/**
 *
 * @param {object} props - Component props.
 * @param {string} props.icon - Key of the icon.
 * @param {string} props.color - Main color to show in the icon.
 * @param {string} props.hoverColor - Color un hover state.
 * @param {number} props.size - Size un px to calculate the width of the icon.
 * @param {number} props.onClick - Click handler of the icon.
 * @returns {JSX.Element} Icon component.
 */
export function Icon({
  icon,
  color,
  hoverColor,
  size,
  onClick,
}: IconProps): JSX.Element {
  const svgContent = Icons[icon];
  const mainColor = color || THEME_COLORS.PRIMARY;
  const secondary = hoverColor || mainColor;

  return (
    <Svg
      fillColor={mainColor}
      hoverFillColor={secondary}
      size={size}
      onClick={onClick}
    >
      {svgContent}
    </Svg>
  );
}

Icon.defaultProps = {
  color: undefined,
  hoverColor: undefined,
  size: undefined,
};
