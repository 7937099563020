import gql from 'graphql-tag';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NOTIFICATION_FRAGMENT on Notification {
    id
    title
    description
    imageURL
    type
    isReaded
    createdAt
    company {
      id
    }
  }
`;

export const SUBSCRIPTION_NOTIFICATION = gql`
  subscription($company: ID, $user: ID!) {
    Notification(
      filter: {
        mutation_in: [create, update, delete]
        node: {
          company: { id: { equals: $company } }
          user: { id: { equals: $user } }
        }
      }
    ) {
      node {
        ...NOTIFICATION_FRAGMENT
      }
      mutation
      updatedFields
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const QUERY_NOTIFICATION = gql`
  query($agency: ID, $user: ID!) {
    notificationsList(
      sort: { createdAt: DESC }
      filter: {
        isDeleted: { equals: false }
        company: { id: { equals: $agency } }
        user: { id: { equals: $user } }
      }
      groupBy: {
        query: {
          id: { as: "count", fn: { aggregate: COUNT } }
          isReaded: { as: "read" }
        }
        having: { alias: "read", bool: { equals: false } }
      }
    ) {
      items {
        ...NOTIFICATION_FRAGMENT
      }
      count
      groups {
        count: Int
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const UPDATE_NOTIFICATION_BY_FILTER = gql`
  mutation(
    $data: NotificationUpdateByFilterInput!
    $filter: NotificationFilter!
  ) {
    notificationUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
      count
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation($data: NotificationUpdateInput!, $id: ID) {
    notificationUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;
