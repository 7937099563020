import React, { useState, useEffect, useCallback } from 'react';
import { Text } from '@cobuildlab/boost';
import { Notification } from '@cobuildlab/salezio-shared';
import { NotificationsListItem } from './NotificationsListItem';

type NotificationsListViewProps = {
  data: Array<Notification>;
  title: string;
  toggle?: () => void;
  refetch: () => void;
  readNotificationsAction: () => void;
};

/**
 * @param {object} props - Props.
 * @param {Array<Notification>} props.data - Notifications array.
 * @param {string} props.title - Group title.
 * @param {()=>void} props.toggle - Mark as read.
 * @param {()=>void} props.refetch - Mark as read.
 * @param {()=>void} props.readNotificationsAction - Mark as read.
 * @returns {React.FC} - Notification list component.
 */
export function NotificationsListView({
  data,
  title,
  refetch,
  toggle = () => {},
  readNotificationsAction,
}: NotificationsListViewProps): JSX.Element | null {
  const [list, setList] = useState(data);

  useEffect(() => {
    setList(data);
    return () => {
      readNotificationsAction();
      refetch();
    };
  }, [data, readNotificationsAction, refetch]);

  const handleChange = useCallback((key, value, id) => {
    setList((currentList) =>
      currentList.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      ),
    );
  }, []);

  const shouldShow = list.length > 0;

  return shouldShow ? (
    <div>
      <Text color="TEXT_LIGHT_GRAY">{title}</Text>
      {list.map((item) => (
        <NotificationsListItem
          key={item.id}
          data={item}
          onChange={handleChange}
          toggle={toggle}
        />
      ))}
    </div>
  ) : null;
}
