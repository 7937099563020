import { Notification, NotificationTypes } from '@cobuildlab/salezio-shared';
import moment from 'moment';
import { ROUTES } from '../../shared/constants/menu-routes';

export type MappedNotificationsList = {
  older: Array<Notification>;
  today: Array<Notification>;
};

/**
 * @param {Array} list - List of notifications.
 * @returns {MappedNotificationsList} - Maped object with today and older reduced list.
 */
export function mapNotificationsList(
  list: Array<Notification>,
): MappedNotificationsList {
  const todayDate = moment().startOf('day');

  const { older, today } = list.reduce<{
    older: Array<Notification>;
    today: Array<Notification>;
  }>(
    (lists, item) => {
      const isToday = moment(item.createdAt).isAfter(todayDate);
      if (isToday) {
        lists.today.push(item);
      } else {
        lists.older.push(item);
      }
      return lists;
    },
    { older: [], today: [] },
  );

  return { older, today };
}

type MappedData = {
  url: string;
  linkText?: string;
  avatar?: string;
  title?: string;
  description?: string;
};

/**
 * @param {Notification} notification - Notification object.
 * @returns {MappedData} - Mapped data to display.
 */
export function getNotificationInfo(
  notification: Notification,
): MappedData | undefined {
  const { title, description, type, imageURL } = notification;

  const data = {
    title,
    description,
    avatar: imageURL,
  };
  switch (type) {
    case NotificationTypes.GENERAL:
      return {
        ...data,
        url: ROUTES.DASHBOARD.path,
        linkText: 'General',
      };

    case NotificationTypes.SALESFORCE:
      return {
        ...data,
        url: ROUTES.SETTINGS.internalPaths?.SETTINGS_SALESFORCE?.path ?? '/',
        linkText: 'Salesforce',
      };
    case NotificationTypes.USERS:
      return {
        ...data,
        url:
          ROUTES.TEAM_MANAGEMENT.internalPaths?.TEAM_MANAGEMENT_USERS?.path ??
          '/',
        linkText: 'Users',
      };

    case NotificationTypes.COMPANY:
      return {
        ...data,
        url: ROUTES.DASHBOARD.path,
        linkText: 'Company',
      };
    case NotificationTypes.ERROR:
      return {
        ...data,
        url: ROUTES.DASHBOARD.path,
        linkText: 'Error',
      };
    case NotificationTypes.GOALS:
      return {
        ...data,
        url: ROUTES.SETTINGS.internalPaths?.GOALS_REPORT?.path ?? '/',
        linkText: 'Goal',
      };
    case NotificationTypes.REPORTS:
      return {
        ...data,
        url: ROUTES.SETTINGS.internalPaths?.GOALS_REPORT?.path ?? '/',
        linkText: 'Report',
      };
    case NotificationTypes.SETTINGS:
      return {
        ...data,
        url: ROUTES.SETTINGS.path,
        linkText: 'Settings',
      };
    case NotificationTypes.TEAM:
      return {
        ...data,
        url:
          ROUTES.TEAM_MANAGEMENT.internalPaths?.TEAM_MANAGEMENT_USERS?.path ??
          '/',
        linkText: 'Team',
      };
    case NotificationTypes.SPRINTS:
      return {
        ...data,
        url: ROUTES.SPRINT_MANAGEMENT.path,
        linkText: 'Sprint',
      };
    default:
      return {
        ...data,
        url: '#',
        linkText: type,
      };
  }
}
