import { ApolloError } from '@apollo/client';
import {
  SALESFORCE_CONNECTION_ERROR,
  ValidationError,
} from '@cobuildlab/salezio-shared';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import { ValidationError as YupValidationError } from 'yup';

/**
 * The onError method for View, shows the error on a Toast.
 *
 * @param err - The Error Object.
 */
export function onErrorMixin(
  err: ValidationError | Error | ApolloError | YupValidationError,
): void {
  if (err.message === SALESFORCE_CONNECTION_ERROR) {
    toast.error(
      <div>
        <h3>Salesforce Connection Error</h3>
        <p>
          Please reconnect the integration with salesforce or contact a company
          admin
        </p>
      </div>,
    );
  } else if (err instanceof ValidationError) {
    toast.error(
      <div>
        <h3>Validation errors </h3>
        {err.arr.map((e) => (
          <p key={e.name}>
            <strong>{e.name}:</strong> {e.message}
          </p>
        ))}
      </div>,
    );
  } else if (err instanceof YupValidationError) {
    toast.error(
      <div>
        {err.inner.map((e) => (
          <p key={e.path}>{e.message}</p>
        ))}
      </div>,
    );
  } else if (err instanceof ApolloError) {
    if (err.message === 'undefined') {
      toast.error(
        <h3>
          Error to connect with Salesforce, please check your Salesforce
          connection in Settings
        </h3>,
      );
      return;
    }
    toast.error(
      <div>
        <h3>{err.message}</h3>

        {err.graphQLErrors.map((e: GraphQLError & { code?: string }) => {
          if (e.message === err.message) return <></>;
          return (
            <p key={e.code}>
              <strong>{e.code}:</strong> {e.message}{' '}
              {e.path && <>in {e.path?.join(',') ?? ''}</>}
            </p>
          );
        })}
      </div>,
    );
  } else {
    toast.error(
      <div>
        <h3>{err.name}</h3>
        <p>{err.message}</p>
      </div>,
    );
  }
}

/**
 * The onError method for View, shows the error on a Toast
 * This is only for functional components.
 *
 * @param err - The Error Object.
 */
export function onErrorMixinFC(err: Error & ValidationError): void {
  console.error(err);
  // toast.error(
  //   <div>
  //     <h3>{err.name}</h3>
  //     <p>{err.message}</p>
  //   </div>,
  // );
  /* if (Array.isArray(err.arr)) {
    toast.errors(err.arr);
  } else {
    toast.error(err.message.replace('GraphQL error:', '').trim());
  } */
}
