import { Auth0Provider as Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import {
  AUTH_CLIENT_REDIRECT_URI,
  AUTH_CLIENT_DOMAIN,
  AUTH_CLIENT_ID,
} from '../../shared/constants/index';

export type AppState = {
  returnTo?: string;
};
/**
 * @param {object}props - Props.
 * @param {JSX.Element}props.children - Children.
 * @returns {JSX.Element} - Component.
 */
export function Auth0Provider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState): void => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Provider
      domain={AUTH_CLIENT_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      redirectUri={AUTH_CLIENT_REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Provider>
  );
}
