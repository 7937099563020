import { FieldForm } from '@cobuildlab/salezio-shared';
import styled from 'styled-components';

/**
 * @param {object} formFields - Fields of the form.
 * @param {object} formFields.items - Items.
 * @param {object} formData - Form data filled.
 * @returns {boolean} - Validate if the Data in the form matches with the fields requirements.
 */
export const validateEndOfDayForm = (
  formFields: {
    items: FieldForm[];
  },
  formData: Record<string, unknown>,
): boolean => {
  const results = formFields.items.map((field): boolean => {
    if (!field.name) {
      return false;
    }
    const fieldValue = formData[field.name];

    if (
      (Boolean(!fieldValue) || Boolean(fieldValue === '')) &&
      field.required
    ) {
      return false;
    }
    if (field.type === 'NUMBER' && Number.isNaN(Number(fieldValue))) {
      return false;
    }

    return true;
  }) as boolean[];

  console.log(results);

  return !results.includes(false);
};

export const columnsSizeAuto = (columns: number): string => {
  let sizes = ['minmax(120px, 220px)'];
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < columns; i++) {
    sizes = [...sizes, 'minmax(120px, 220px)'];
  }
  sizes = [...sizes, 'minmax(120px, 220px)'];
  return sizes.join(' ');
};

/**
 * @param {string | boolean} name - Name, if this value is true or false this function will return their value in string.
 * @returns {string} - Value returned.
 */
export const displayRightName = (name: string | boolean): string => {
  if (typeof name === 'string') return name;
  if (name === true) return 'True';
  if (name === false) return 'False';
  return '';
};

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
