import { Text } from '@cobuildlab/boost';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../constants/menu-routes';

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;

  @media screen and (max-width: 615px) {
    justify-content: center;
  }
`;

/**
 * Site title component to use in topbar.
 *
 * @returns {JSX.Element} - Site title component.
 */
export function SiteTitle(): JSX.Element {
  const location = useLocation();
  const currentPath = location.pathname;
  const title =
    routes.find(
      (route) =>
        route.path === currentPath ||
        Object.values(route.internalPaths || {}).find(
          (internal) => internal?.path === currentPath,
        ) !== undefined,
    )?.name || 'Salez.io';
  return (
    <TitleContainer>
      <Text color="PRIMARY" weight="semibold">
        {title}
      </Text>
    </TitleContainer>
  );
}
