import styled from 'styled-components';
import { Loader as BoostLoader, Text } from '@cobuildlab/boost';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ChildrenConatiner = styled(Text)`
  margin-bottom: 1em !important;
`;

const LoaderOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.795);
  z-index: 10;
  cursor: not-allowed;
`;

/**
 * Main loader component.
 *
 * @param {object} props - Props.
 * @param {React.ReactNode} props.children - Children.
 * @returns {JSX.Element} Loader component.
 */
export function Loader({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const child = children ? (
    <ChildrenConatiner color="PRIMARY">{children}</ChildrenConatiner>
  ) : null;
  return (
    <Container data-testid="layout-container">
      {child}
      <BoostLoader size="lg" color="PRIMARY" />
    </Container>
  );
}

/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @returns Loader overlay.
 */
export function LoaderOverlay({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <LoaderOverlayContainer>
      <Loader>{children}</Loader>
    </LoaderOverlayContainer>
  );
}
