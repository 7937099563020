/* eslint-disable react/require-default-props */
import styled from 'styled-components';
import { Dropdown as DropdownBoost, Text } from '@cobuildlab/boost';
import { Chevron } from '../../icons/Chevron';
import { UnStyledButton } from '../ui/buttons/Buttons';

export const DropdownWrap = styled.div`
  border: 1px solid #ced7dd;
  border-radius: 4px;
  min-width: 200px;
  justify-content: flex-start;
  align-items: center;
`;

export const DropdownOption = styled.div<{ active?: boolean }>`
  color: ${(props) => (props.active ? 'var(--primary)' : '#384a59')};
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 28px;
  padding: 0px 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OptionContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 11px 0px;
  &:hover {
    background-color: rgb(244, 247, 249);
  }
  ${(props) => props.disabled && 'cursor: not-allowed'}
`;

const HeadDropdown = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 36px;
`;

const BodyHeader = styled.div`
  padding: 11px 22px;
  width: 205px;
  border-bottom: 1px solid #e9eff4;
`;
export const TextSpacing = styled.p`
  color: #323c47;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 27px;
  font-weight: 600;
  margin-right: 15px;
  margin-top: -2px;
  text-transform: uppercase;
`;
export const CurrentBadge = styled.span`
  box-sizing: border-box;
  height: 20px;
  width: 57px;
  border: 1px solid #eaeff3;
  border-radius: 5px;
  color: #9b9b9b;
  font-family: 'Poppins';
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export interface DropdownOptionType {
  value: OptionValue;
  disabled?: boolean;
  active?: boolean;
  name: string;
  onDisabledClick?: () => void;
}
interface MappedOptions {
  filteredOptions: Array<DropdownOptionType>;
  selectedOption: DropdownOptionType | null;
}
type OptionValue = string | number;
interface DropdownProps {
  offset: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  currentOption?: OptionValue;
  onChange?: (value: OptionValue) => void;
  defaultOpen?: boolean;
  options: Array<DropdownOptionType>;
  bodyHeader?: string;
  placheholder?: string;
  showActiveOption?: boolean;
  forceRender?: boolean;
}
interface BoostDropdownProps {
  // eslint-disable-next-line react/no-unused-prop-types
  toggleDropdown: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen: boolean;
}
const OptionButton = styled(UnStyledButton)`
  width: 100%;
  display: block;
`;

export const Dropdown = ({
  options,
  currentOption = '',
  onChange = () => {},
  showActiveOption = true,
  bodyHeader = '',
  placheholder = '',
  defaultOpen = false,
  offset = 'none',
  forceRender = false,
}: DropdownProps): JSX.Element => {
  const { filteredOptions, selectedOption } = options.reduce<MappedOptions>(
    (previus, option) => {
      if (option.value !== currentOption) {
        previus.filteredOptions.push(option);
        return previus;
      }
      return {
        ...previus,
        selectedOption: option,
      };
    },
    {
      filteredOptions: [],
      selectedOption: null,
    },
  );

  const minWidth = '';

  const onlyOneOption = options.length === 1;

  if (onlyOneOption && !forceRender) {
    return (
      <HeadDropdown>{options[0] ? options[0].name : placheholder}</HeadDropdown>
    );
  }
  return (
    <DropdownBoost defaultOpen={defaultOpen} data-testid="dropdown">
      <DropdownBoost.Head
        style={{ position: 'relative', minWidth }}
        stopClickPropagation
      >
        {({ isOpen, toggleDropdown }: BoostDropdownProps) => (
          <>
            <OptionButton
              onClick={(e) => {
                toggleDropdown();
                e.stopPropagation();
              }}
              data-testid="dropdown__head"
            >
              <HeadDropdown>
                {selectedOption ? selectedOption.name : placheholder}
              </HeadDropdown>
              <Chevron direction={isOpen ? 'UP' : 'DOWN'} right="16px" />
            </OptionButton>
          </>
        )}
      </DropdownBoost.Head>
      <DropdownBoost.Body
        offset={offset}
        background="white"
        closeOnClickOutside
        role="listbox"
      >
        {({ toggleDropdown }: BoostDropdownProps) => {
          const renderOptionsMap = (
            option: DropdownOptionType,
          ): JSX.Element => {
            const active = option.value === currentOption;

            const handleClick = (): void => {
              if (!active && !option.disabled) {
                if (onChange) onChange(option.value);
                toggleDropdown();
              }

              if (option.disabled && option.onDisabledClick) {
                option.onDisabledClick();
              }
            };

            // eslint-disable-next-line no-nested-ternary
            const color = active
              ? 'PRIMARY'
              : option.disabled
              ? 'DANGER'
              : undefined;

            return (
              <OptionButton
                onClick={handleClick}
                key={option.value}
                role="option"
                aria-selected={active}
              >
                <OptionContainer disabled={option.disabled} key={option.value}>
                  <DropdownOption>
                    <Text color={color}>{option.name}</Text>
                    {active && <CurrentBadge> Current</CurrentBadge>}
                    {option.disabled && <CurrentBadge> Disabled</CurrentBadge>}
                  </DropdownOption>
                </OptionContainer>
              </OptionButton>
            );
          };
          const optionsToRender =
            filteredOptions.length > 0
              ? filteredOptions.map(renderOptionsMap)
              : null;
          return (
            <div>
              {bodyHeader !== '' && (
                <BodyHeader>
                  <TextSpacing>{bodyHeader}</TextSpacing>
                </BodyHeader>
              )}
              {showActiveOption
                ? options.map(renderOptionsMap)
                : optionsToRender}
            </div>
          );
        }}
      </DropdownBoost.Body>
    </DropdownBoost>
  );
};
