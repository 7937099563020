import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { createAction } from '@cobuildlab/react-simple-state';
import { User } from '@cobuildlab/salezio-shared';
import {
  OnApolloClient,
  OnSessionError,
  OnSessionFetch,
  OnToken,
  OnTokenError,
} from './auth-events';
import { USER_QUERY, SESSION_QUERY } from './auth-queries';
// import { OnApolloToken, OnApolloTokenError } from '../../shared/apollo/apollo-events';

export const fetchToken = createAction(
  OnToken,
  OnTokenError,
  async (auth: Auth0ContextInterface) => {
    const token = await auth.getIdTokenClaims();

    // eslint-disable-next-line no-underscore-dangle
    return token.__raw;
  },
);

/**
 * Fetches a User Profile by Id or the logged User if no Id provided.
 *
 * @param id - The user id to fetch, if zero, the currently authenticated user is fetched.
 *
 * @returns - The request result.
 */
export const fetchUser = async (id = ''): Promise<User | null> => {
  const client = OnApolloClient.get();
  let response = null;

  try {
    if (id === '') {
      response = await client?.query({
        query: USER_QUERY,
        fetchPolicy: 'network-only',
      });
    } else {
      response = await client?.query({
        query: USER_QUERY,
        fetchPolicy: 'network-only',
        variables: { id },
      });
    }
  } catch (e) {
    console.error('fetchUser', e);
    throw e;
  }

  console.log('fetchUser', response?.data.user);

  return response?.data.user;
};

/**
 * Fetches the logged User Profile.
 *
 * @param client - Apollo.
 * @returns - A void Promise.
 */
export const fetchSession = async (
  client: ApolloClient<unknown>,
): Promise<void | { user: User }> => {
  let response: ApolloQueryResult<{ user: User }> | undefined;

  try {
    response = await client?.query<{ user: User }>({
      query: SESSION_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        // id: user?.id,
        // agencyInvitationFilter: { email: { equals: user?.email } },
        // companyInvitationFilter: { email: { equals: user?.email } },
      },
    });
  } catch (e) {
    console.error('fetchSession', e);

    return OnSessionError.dispatch(e);
  }

  console.log('fetchSession', response?.data);

  OnSessionFetch.dispatch(response?.data);

  return response?.data;
};
