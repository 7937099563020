import React from 'react';
import { Dialog, Grid, Button, Text } from '@cobuildlab/boost';
import { ActionBtn } from './ui/buttons/ActionBtn';

type YesNoDialogProps = {
  type?:
    | 'primary'
    | 'default'
    | 'neutral'
    | 'danger'
    | 'success'
    | 'warning'
    | undefined;
  onYes: () => void;
  onClose: () => void;
  onNo: () => void;
  title: string;
  text: React.ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  yesText?: string;
  noText?: string;
};

export const YesNoDialog: React.FC<YesNoDialogProps> = ({
  type,
  onYes,
  onNo,
  text,
  isOpen,
  title,
  yesText = 'Yes',
  noText = 'No',
  onClose,
  isLoading = false,
}) => (
  <Dialog size="sm" isOpen={isOpen}>
    <Dialog.Header title={title} onClose={onClose} />
    <Dialog.Body scrollable>
      <Grid.Layout gap="sm" stretch>
        <Grid.Box>
          <Text>{text}</Text>
        </Grid.Box>
      </Grid.Layout>
    </Dialog.Body>
    <Dialog.Footer>
      <Button color="neutral" variant="outlined" onClick={onNo}>
        {noText}
      </Button>
      <ActionBtn
        color={type}
        text={yesText}
        onClick={onYes}
        loading={isLoading}
      />
    </Dialog.Footer>
  </Dialog>
);
