import { createTheme } from '@cobuildlab/boost';
import { THEME_COLORS } from '../constants/colors';

export const salezioTheme = createTheme({
  /** Change the pallete of the color. */
  COLORS: {
    PRIMARY: THEME_COLORS.PRIMARY,
    PRIMARY_LIGHT: THEME_COLORS.PRIMARY_CTA,
    PRIMARY_TEXT_COLOR: THEME_COLORS.DARK_SLATE,
    SECONDARY_TEXT_COLOR: THEME_COLORS.SECONDARY_TEXT_COLOR,
    DISABLED_TEXT_COLOR: THEME_COLORS.DISABLED_TEXT_COLOR,
  },
  FONTS: {
    OVERLINE_1: {
      color: THEME_COLORS.SECONDARY_TEXT_COLOR,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      fontWeight: '400',
      letterSpacing: '0.2px',
    },
  },
});
