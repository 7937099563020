import { CompanyRoleNames } from '@cobuildlab/salezio-shared';
import { ROUTES_RULES } from './session-model';
import { Session, UserSession } from './session-types';

/** : Session | null.
 *
 * @param {UserSession} data - Session data.
 * @returns {Session} Mapped session data.
 */
export function mapSessionState(data: UserSession | null): Session | null {
  if (!data) {
    return null;
  }
  const {
    id,
    firstName,
    lastName,
    email,
    userCompanyUserRelation,
    selectedCompany,
    userTeamUserRelation,
    shouldCreateCompany,
    shouldSendInvitations,
    avatar,
  } = data;

  const user = {
    id,
    firstName,
    lastName,
    email,
    shouldCreateCompany,
    userCompanyUserRelation,
    selectedCompany,
    userTeamUserRelation,
    avatar,
    shouldSendInvitations,
  };

  const company = userCompanyUserRelation?.items.find(
    (item) => item.company.id === selectedCompany?.id,
  );

  const companyData = {
    id: company?.company.id as string,
    name: company?.company.name as string,
    role: company?.role as CompanyRoleNames,
    disabled: company?.disabled ?? true,
  };

  const companies = userCompanyUserRelation.items.map((item) => ({
    ...item.company,
    disabled: item.role === CompanyRoleNames.ADMIN ? false : item.disabled,
  }));

  return {
    user,
    selectedCompany: companyData,
    companies,
  };
}

/**
 * @param {Session} data - Data to map.
 * @param {string} location - Data to map.
 * @param {boolean} loadingInvitation - Data to map.
 * @returns {[boolean, string]} To handle the Session redirects and validations.
 */
export function checkRouteRules(
  data: Session,
  location: string,
  loadingInvitation: boolean,
): [boolean, string] {
  const truthyCondition = ROUTES_RULES.find(
    (item) =>
      item.condition({ data, location, loadingInvitation }) &&
      item.redirect !== location,
  );

  if (truthyCondition) {
    return [false, truthyCondition.redirect];
  }
  return [true, ''];
}
