import { createAction } from '@cobuildlab/react-simple-state';
import {
  Company,
  EndOfDayFormListResponse,
  EndOfDayReportFilter,
  Query,
  SuccessResponse,
  Team,
  GoalFilter,
  GoalListResponse,
  EndOfDayForm,
  EndOfDayReport,
  EndOfDayReportCreateInput,
} from '@cobuildlab/salezio-shared';
import moment from 'moment';
import { toast } from 'react-toastify';
import { apolloClient } from '../../shared/apollo';
import { OnFetchEndOfDayFormError } from '../team-management/team-management-events';
import { FETCH_END_OF_DAY_FORMS_ALL } from '../team-management/team-management-queries';
import {
  OnCreateGoal,
  OnCreateGoalError,
  OnFetchCompanyTeams,
  OnFetchCompanyTeamsError,
  OnFetchEndOfDayForms,
  OnFetchGoals,
  OnFetchGoalsError,
  OnFetchReports,
  OnFetchReportsError,
  OnFetchTeamEndOfDayForms,
  OnFetchTeamEndOfDayFormsError,
  OnSaveEndOfDayReport,
  OnSaveEndOfDayReportError,
} from './reports-events';
import {
  COMPANY_TEAMS,
  CREATE_GOAL,
  FETCH_GOALS,
  SAVE_END_OF_DAY_REPORT,
  FETCH_TEAM_END_OF_DAY_FORMS,
  FETCH_REPORTS_AND_FIELDS,
} from './reports-queries';
import { GoalData, ReportFilter, GoalsFilter } from './reports-type';

/**
 * @returns {Promise<SuccessResponse>} Create goal success response.
 */
export const createGoal = createAction(
  OnCreateGoal,
  OnCreateGoalError,
  async (data: GoalData): Promise<SuccessResponse> => {
    const variables = {
      data,
    };

    try {
      await apolloClient.mutate({
        mutation: CREATE_GOAL,
        variables,
      });
      apolloClient.cache.reset();
      toast.success('Goal Created Successfully');
      return { success: true };
    } catch {
      toast.error('Error creating goal');
      return { success: false };
    }
  },
);

/**
 * @returns {Promise<Team[]>} List of teams.
 */
export const fetchCompanyTeams = createAction(
  OnFetchCompanyTeams,
  OnFetchCompanyTeamsError,
  async (companyId: string): Promise<Team[]> => {
    const variables = {
      companyId,
    };

    let response;
    try {
      response = await apolloClient.query<{ company: Company }>({
        query: COMPANY_TEAMS,
        variables,
      });
    } catch (error) {
      toast.error('Error fetching teams');
    }
    return response?.data.company.companyTeamRelation?.items as Team[];
  },
);

/**
 * @returns {Promise<GoalListResponse>} List of goals.
 */
export const fetchGoals = createAction(
  OnFetchGoals,
  OnFetchGoalsError,
  async (data: GoalsFilter | undefined): Promise<any> => {
    let filter: GoalFilter = {
      AND: data ? [{ OR: [] }] : undefined,
    };

    if (data?.from) {
      filter = {
        AND: [
          {
            OR: [
              ...(filter.AND?.find((item) => item.OR)?.OR as []),
              {
                from: {
                  gt: data.from,
                },
              },
            ],
          },
        ],
      };
    }

    if (data?.to) {
      filter = {
        AND: [
          {
            OR: [
              ...(filter.AND?.find((item) => item.OR)?.OR as []),
              {
                to: {
                  gt: data.to,
                },
              },
            ],
          },
        ],
      };
    }

    if (data?.goalName) {
      filter = {
        AND: [
          ...(filter.AND as []),
          {
            name: {
              contains: data.goalName,
            },
          },
        ],
      };
    }

    let teamFilter;
    if (data?.team) {
      filter = {
        AND: [
          ...(filter.AND as []),
          {
            goalTeamRelation: {
              some: {
                name: {
                  contains: data.team,
                },
              },
            },
          },
        ],
      };
      teamFilter = { name: { contains: data.team } };
    }

    const response = await apolloClient.query<Query>({
      query: FETCH_GOALS,
      variables: { filter, teamFilter },
      fetchPolicy: 'no-cache',
    });
    return response;
  },
);

/**
 * @returns {Promise<any>} List of endOfDayForm.
 */
export const fetchEndOfDayForms = createAction(
  OnFetchEndOfDayForms,
  OnFetchEndOfDayFormError,
  async (compId: string): Promise<any> => {
    const variables = {
      filter: {
        company: {
          id: {
            equals: compId,
          },
        },
      },
    };

    try {
      return await apolloClient.query<EndOfDayFormListResponse>({
        query: FETCH_END_OF_DAY_FORMS_ALL,
        variables,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      toast.error('Error fetching end of day forms');
      return error;
    }
  },
);

/**
 * @returns {Promise<any>} -.
 */
export const saveEndOfDayReport = createAction(
  OnSaveEndOfDayReport,
  OnSaveEndOfDayReportError,
  async (userTeamId: string, formId: string, jsonData: unknown) => {
    const variables: {
      data: EndOfDayReportCreateInput;
    } = {
      data: {
        endOfDayForm: {
          connect: {
            id: formId,
          },
        },
        companyUser: {
          connect: {
            id: userTeamId,
          },
        },
        data: jsonData,
      },
    };

    const result = await apolloClient.mutate<{
      endOfDayReportCreate: EndOfDayReport;
    }>({
      mutation: SAVE_END_OF_DAY_REPORT,
      variables,
    });

    if (!result.data) {
      throw Error();
    }

    return result.data.endOfDayReportCreate;
  },
);

/**
 * @returns {Promise<EndOfDayForm[]>} -.
 */
export const fetchTeamEndOfDayForms = createAction(
  OnFetchTeamEndOfDayForms,
  OnFetchTeamEndOfDayFormsError,
  async (companyId): Promise<EndOfDayForm[]> => {
    const response = await apolloClient.query<Query>({
      query: FETCH_TEAM_END_OF_DAY_FORMS,
      variables: { companyId },
    });

    return response.data.endOfDayFormsList.items as EndOfDayForm[];
  },
);

/**
 * @returns {Promise<EndOfDayReport[]>} -.
 */
export const fetchReportsAndFields = createAction(
  OnFetchReports,
  OnFetchReportsError,
  async (
    formName: string,
    page: number,
    customFilter?: ReportFilter & { search?: string },
  ): Promise<EndOfDayReport[]> => {
    const variables: {
      filter: EndOfDayReportFilter;
      skip: number;
    } = {
      filter: {
        endOfDayForm: {
          name: {
            equals: formName,
          },
        },
      },
      skip: page * 10,
    };

    if (customFilter) {
      let AND: EndOfDayReportFilter[] = [];

      if (customFilter.agent) {
        AND = [
          {
            createdBy: {
              email: {
                equals: customFilter.agent,
              },
            },
          },
        ];
      }

      if (customFilter.from) {
        AND = [
          ...AND,
          {
            createdAt: {
              gt: moment(customFilter.from).toISOString(),
            },
          },
        ];
      }

      if (customFilter.to) {
        AND = [
          ...AND,
          {
            createdAt: {
              lt: moment(customFilter.to).toISOString(),
            },
          },
        ];
      }

      if (customFilter?.search) {
        AND = [
          ...AND,
          {
            OR: [
              {
                createdBy: {
                  firstName: {
                    contains: customFilter?.search,
                  },
                },
              },
              {
                createdBy: {
                  lastName: {
                    contains: customFilter?.search,
                  },
                },
              },
            ],
          },
        ];
      }

      Object.assign(variables.filter, { AND });
    }

    const { data } = await apolloClient.query<Query>({
      query: FETCH_REPORTS_AND_FIELDS,
      variables,
    });

    return data.endOfDayReportsList.items as EndOfDayReport[];
  },
);
