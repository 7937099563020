/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 *
 * Validate that element is actually an input
 */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable jsdoc/require-description-complete-sentence */
export function isElementInput<T extends HTMLElement>(element: T): boolean {
  return element instanceof HTMLInputElement;
}

/**
 * Return as HTMLInputElement if element is instance of HTMLInputElement.
 * Otherwise returns null.
 *
 * @param {} element -  .
 * @returns { HTMLInputElement | null } .
 */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable jsdoc/require-description-complete-sentence */
export function getAsInputElement<T extends HTMLElement>(
  element: T,
): HTMLInputElement | null {
  return element instanceof HTMLInputElement ? element : null;
}

/**
 * Capitalize string.
 *
 * @param {string} text - String to capitalize.
 * @returns { HTMLInputElement | null } .
 */
export function capitalizeString(text = ' '): string {
  const [t, ...rest] = text.toLowerCase().split('');
  return [t.toUpperCase(), ...rest].join('');
}

/** Change input date (mm/dd/yyyy) to (yyyy-mm-dd)
 */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable jsdoc/require-description-complete-sentence */
export function changeInputDate(date: string): string {
  const [month, day, year] = date.split('/');
  if (!(month && day && year)) return date;

  return [year, month, day].join('-');
}

/**
 * Function to map the query string as state.
 *
 * @param search - Search query string
 * @param keys - Arrays of key to get.
 * @param defaultValues - Default values
 * @returns Returned state from query
 */
export function getStateFromSearchStringQuery<T>(
  search: string,
  keys: (keyof T)[],
  defaultValues: T,
): T {
  const query = new URLSearchParams(search);

  const queryParams = keys.reduce((prev, current) => {
    const value = query.get(current.toString());
    if (value) {
      return { ...prev, [current.toString()]: value };
    }
    return prev;
  }, defaultValues);

  return queryParams;
}
