import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { Layout } from '../shared/components/Layout';
import { Auth } from '../modules/auth/Auth';
import { Register } from '../modules/auth/Register';
import { AuthCallback } from '../modules/auth/auth-callback/AuthCallback';
import { Logout } from '../modules/auth/Logout';
import { useSetupAuthToken } from '../modules/auth/auth-hooks';
import { Loader } from '../shared/components/MainLoader';
import { FIXED_ROUTES, ROUTES } from '../shared/constants/menu-routes';
import { Session } from '../modules/session/Session';
import TeamManagementView from '../modules/team-management/TeamManagementView';
import { SalesForceView } from '../modules/salesforce/SalesForceView';
import { RoleRoute } from './RoleRoute';
import { lazyWithRetry } from '../shared/components/LazyWithRetry';
import { LazyCompanyInvitationView } from '../modules/lazy/LazyComponents';
// import { Invitation } from '../modules/invitation/Invitation';

/*
WARNING: It is not necessary to add a protected route to each component within session, 
an example of how to add the routes I will leave below

  <ProtectedRoute path="/">
    <Session>
     <Route path="/1" component={AuthCallback} />
     <Route path="/2/4" component={AuthCallback} />
     <Route path="/3/5" component={AuthCallback} />
    </Session>
  </ProtectedRoute>

*/

const CompanyCreation = lazyWithRetry(
  () => import('../modules/onboarding/OnboardingView'),
);
const Dashboard = lazyWithRetry(
  () => import('../modules/dashboard/DashboardView'),
);
const SprintsView = lazyWithRetry(
  () => import('../modules/sprints/SprintsView'),
);
const CreateStaticSprintView = lazyWithRetry(
  () => import('../modules/sprints/CreateStaticSprintView'),
);
const CreateDynamicSprintView = lazyWithRetry(
  () => import('../modules/sprints/CreateDynamicSprintView'),
);
const CreateReportSprintView = lazyWithRetry(
  () => import('../modules/sprints/CreateReportSprintView'),
);
const PreDialerSprintView = lazyWithRetry(
  () => import('../modules/sprints/PreDialerSprintView'),
);
const LeadInformationView = lazyWithRetry(
  () => import('../modules/leads/LeadDetail'),
);

const Invitation = lazyWithRetry(
  () => import('../modules/invitation/Invitation'),
);
const BillingView = lazyWithRetry(
  () => import('../modules/settings/billing/BillingView'),
);
const ProfileView = lazyWithRetry(
  () => import('../modules/settings/profile/ProfileView'),
);
const SettingsGeneralView = lazyWithRetry(
  () => import('../modules/settings/general/SettingsGeneralView'),
);
const SettingsView = lazyWithRetry(
  () => import('../modules/settings/SettingsView'),
);

const ReportsView = lazyWithRetry(
  () => import('../modules/reports/ReportsView'),
);

const SettingsSalesforceView = lazyWithRetry(
  () => import('../modules/settings/salesforce/SalesforceSettingsView'),
);

const GoalsReportView = lazyWithRetry(
  () => import('../modules/reports/goals-report/GoalsReportView'),
);

const EndOfDayReportView = lazyWithRetry(
  () => import('../modules/reports/end-of-day-report/EndOfDayReportView'),
);
const NoteReportView = lazyWithRetry(
  () => import('../modules/reports/note-report/NoteReportView'),
);

const TeamManagementUsersView = lazyWithRetry(
  () =>
    import(
      '../modules/team-management/team-managment-users/TeamManagementUsersView'
    ),
);

const TeamManagementTeamView = lazyWithRetry(
  () =>
    import(
      '../modules/team-management/team-managment-team/TeamManagementTeamView'
    ),
);

const TeamManagementAdminView = lazyWithRetry(
  () =>
    import(
      '../modules/team-management/team-management-admin/TeamManagementAdminView'
    ),
);

const TeamManagementViewDefault = lazyWithRetry(
  () => import('../modules/team-management/TeamManagementViewDefault'),
);
const SettingsViewDefault = lazyWithRetry(
  () => import('../modules/settings/SettingsViewDefault'),
);
const TeamManagemenTeamDetailsView = lazyWithRetry(
  () =>
    import(
      '../modules/team-management/team-details/TeamManagemenTeamDetailsView'
    ),
);
const PhoneNumbersView = lazyWithRetry(
  () => import('../modules/settings/phone-numbers/PhoneNumbersView'),
);
const SprintActivityView = lazyWithRetry(
  () => import('../modules/sprint-activity/SprintActivityView'),
);
const SprintDetailsView = lazyWithRetry(
  () => import('../modules/sprint-activity/sprint-details/SprintDetailsView'),
);
const SprintCallsView = lazyWithRetry(
  () => import('../modules/sprint-activity/sprint-details/SprintCallsView'),
);

const CompanyFormsView = lazyWithRetry(
  () => import('../modules/settings/forms/CompanyFormsView'),
);

export const Routes: React.FC = () => {
  const [loading, loadingToken] = useSetupAuthToken();
  const error = (e: string): JSX.Element => {
    console.log(e);
    return <div>permission error {e}</div>;
  };

  // This loading will only show until the auth client loads.
  return loading ? (
    <Loader />
  ) : (
    <Switch>
      <Route exact path={FIXED_ROUTES.AUTH} component={Auth} />
      <Route exact path={FIXED_ROUTES.REGISTER} component={Register} />
      <Route exact path={FIXED_ROUTES.AUTH_CALLBACK} component={AuthCallback} />
      <Route exact path={FIXED_ROUTES.LOGOUT} component={Logout} />
      <Route path={FIXED_ROUTES.SALESFORCE} component={SalesForceView} />
      <Session loading={loadingToken}>
        {(loadingSession) =>
          loadingSession ? (
            <Loader />
          ) : (
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route
                  path={FIXED_ROUTES.ONBOARDING}
                  component={CompanyCreation}
                  exact
                />
                <Route
                  path={FIXED_ROUTES.INVITATIONS}
                  component={LazyCompanyInvitationView}
                  exact
                />
                <Route exact>
                  <Layout>
                    <Suspense fallback={<Loader />}>
                      <Route path="/" component={Invitation} />
                      <Switch>
                        <RoleRoute
                          path={ROUTES.DASHBOARD.path}
                          permission={PermissionsNames.DASHBOARD_VIEW}
                          exact
                        >
                          <Dashboard />
                        </RoleRoute>

                        <RoleRoute
                          path={ROUTES.SPRINT_MANAGEMENT.path}
                          permission={PermissionsNames.SPRINT_VIEW}
                          render={({ match }) => (
                            <Switch>
                              <Route
                                path={match.url}
                                component={SprintsView}
                                exact
                              />
                              <Route
                                path={
                                  ROUTES.SPRINT_MANAGEMENT.internalPaths
                                    ?.NEW_REPORT_SPRINT?.path
                                }
                              >
                                <CreateReportSprintView />
                              </Route>
                              <Route
                                path={
                                  ROUTES.SPRINT_MANAGEMENT.internalPaths
                                    ?.NEW_SMART_SPRINT?.path
                                }
                              >
                                <CreateDynamicSprintView />
                              </Route>
                              <Route
                                path={
                                  ROUTES.SPRINT_MANAGEMENT.internalPaths
                                    ?.NEW_STATIC_SPRINT?.path
                                }
                              >
                                <CreateStaticSprintView />
                              </Route>
                              <Route
                                path={
                                  ROUTES.SPRINT_MANAGEMENT.internalPaths
                                    ?.PRE_DIALER_SPRINT?.path
                                }
                              >
                                <PreDialerSprintView />
                              </Route>
                            </Switch>
                          )}
                        />
                        <RoleRoute
                          path={ROUTES.SETTINGS.path}
                          permission={PermissionsNames.DASHBOARD_VIEW}
                          render={(props) => (
                            <SettingsView {...props}>
                              <Route
                                path={
                                  ROUTES.SETTINGS.internalPaths
                                    ?.SETTINGS_PROFILE?.path
                                }
                                component={ProfileView}
                                exact
                              />
                              <Route
                                path={
                                  ROUTES.SETTINGS.internalPaths
                                    ?.SETTINGS_GENERAL?.path
                                }
                                component={SettingsGeneralView}
                                exact
                              />
                              <RoleRoute
                                exact
                                path={
                                  ROUTES.SETTINGS.internalPaths
                                    ?.SETTINGS_SALESFORCE?.path
                                }
                                permission={PermissionsNames.DASHBOARD_VIEW}
                              >
                                <SettingsSalesforceView />
                              </RoleRoute>
                              <RoleRoute
                                exact
                                path={
                                  ROUTES.SETTINGS.internalPaths
                                    ?.SETTINGS_BILLING?.path
                                }
                                permission={PermissionsNames.DASHBOARD_VIEW}
                              >
                                <BillingView />
                              </RoleRoute>
                              <RoleRoute
                                exact
                                path={ROUTES.SETTINGS.path}
                                permission={PermissionsNames.DASHBOARD_VIEW}
                              >
                                <SettingsViewDefault />
                              </RoleRoute>
                              <RoleRoute
                                exact
                                path={
                                  ROUTES.SETTINGS.internalPaths?.PHONE_NUMBERS
                                    ?.path
                                }
                              >
                                <PhoneNumbersView />
                              </RoleRoute>
                              <RoleRoute
                                exact
                                path={
                                  ROUTES.SETTINGS.internalPaths?.SETTINGS_FORMS
                                    ?.path
                                }
                              >
                                <CompanyFormsView />
                              </RoleRoute>
                            </SettingsView>
                          )}
                        />
                        <RoleRoute
                          path={ROUTES.REPORTS.path}
                          permission={PermissionsNames.DASHBOARD_VIEW}
                          render={(props) => (
                            <ReportsView {...props}>
                              <Switch>
                                <RoleRoute
                                  exact
                                  path={ROUTES.REPORTS.path}
                                  permission={PermissionsNames.DASHBOARD_VIEW}
                                >
                                  <EndOfDayReportView />
                                </RoleRoute>
                                <RoleRoute
                                  exact
                                  path={
                                    ROUTES.REPORTS.internalPaths
                                      ?.END_OF_DAY_REPORT?.path
                                  }
                                  permission={PermissionsNames.DASHBOARD_VIEW}
                                >
                                  <EndOfDayReportView />
                                </RoleRoute>
                                <RoleRoute
                                  exact
                                  path={
                                    ROUTES.REPORTS.internalPaths?.NOTE_REPORT
                                      ?.path
                                  }
                                  permission={
                                    PermissionsNames.TEAM_MANAGEMENT_VIEW
                                  }
                                >
                                  <NoteReportView />
                                </RoleRoute>
                                <RoleRoute
                                  exact
                                  path={
                                    ROUTES.REPORTS.internalPaths?.GOALS_REPORT
                                      ?.path
                                  }
                                  permission={PermissionsNames.DASHBOARD_VIEW}
                                >
                                  <GoalsReportView />
                                </RoleRoute>
                              </Switch>
                            </ReportsView>
                          )}
                        />
                        <RoleRoute
                          exact
                          path={FIXED_ROUTES.TEAM_DETAILS}
                          permission={
                            PermissionsNames.TEAM_MANAGEMENT_USER_VIEW
                          }
                        >
                          <TeamManagemenTeamDetailsView />
                        </RoleRoute>
                        <RoleRoute
                          exact
                          permission={PermissionsNames.SPRINT_VIEW}
                          path={ROUTES.SPRINT_ACTIVITY.path}
                        >
                          <SprintActivityView />
                        </RoleRoute>
                        <RoleRoute
                          exact
                          path={`${ROUTES.SPRINT_ACTIVITY.internalPaths?.SPRINT?.path}/${ROUTES.SPRINT_ACTIVITY.internalPaths?.SPRINT?.pathWithParam}`}
                          permission={PermissionsNames.SPRINT_VIEW}
                        >
                          <SprintDetailsView />
                        </RoleRoute>
                        <RoleRoute
                          exact
                          path={`${ROUTES.SPRINT_ACTIVITY.internalPaths?.CALLS?.path}/${ROUTES.SPRINT_ACTIVITY.internalPaths?.CALLS?.pathWithParam}`}
                          permission={PermissionsNames.SPRINT_VIEW}
                        >
                          <SprintCallsView />
                        </RoleRoute>
                        <RoleRoute
                          exact
                          path={`${ROUTES.SPRINT_ACTIVITY.internalPaths?.LEAD?.path}/${ROUTES.SPRINT_ACTIVITY.internalPaths?.LEAD?.pathWithParam}`}
                        >
                          <LeadInformationView />
                        </RoleRoute>
                        <RoleRoute
                          path={ROUTES.TEAM_MANAGEMENT.path}
                          permission={PermissionsNames.TEAM_MANAGEMENT_VIEW}
                          error={error}
                          render={(props) => (
                            <TeamManagementView {...props}>
                              <Switch>
                                <RoleRoute
                                  permission={
                                    PermissionsNames.TEAM_MANAGEMENT_USER_VIEW
                                  }
                                  path={
                                    ROUTES.TEAM_MANAGEMENT.internalPaths
                                      ?.TEAM_MANAGEMENT_USERS?.path
                                  }
                                  exact
                                >
                                  <TeamManagementUsersView />
                                </RoleRoute>
                                <RoleRoute
                                  exact
                                  path={
                                    ROUTES.TEAM_MANAGEMENT.internalPaths
                                      ?.TEAM_MANAGEMENT_TEAM?.path
                                  }
                                  permission={
                                    PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW
                                  }
                                >
                                  <TeamManagementTeamView />
                                </RoleRoute>
                                <RoleRoute
                                  exact
                                  path={
                                    ROUTES.TEAM_MANAGEMENT.internalPaths
                                      ?.TEAM_MANAGEMENT_ADMIN?.path
                                  }
                                  permission={PermissionsNames.COMPANY_EDIT}
                                >
                                  <TeamManagementAdminView />
                                </RoleRoute>
                                <RoleRoute
                                  permission={
                                    PermissionsNames.TEAM_MANAGEMENT_VIEW
                                  }
                                  path={ROUTES.TEAM_MANAGEMENT.path}
                                  exact
                                >
                                  <TeamManagementViewDefault />
                                </RoleRoute>
                              </Switch>
                            </TeamManagementView>
                          )}
                        />
                      </Switch>
                    </Suspense>
                  </Layout>
                </Route>
              </Switch>
            </Suspense>
          )
        }
      </Session>
    </Switch>
  );
};
