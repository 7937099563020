import {
  Dialog,
  InputField,
  NoData,
  Row,
  Select,
  Text,
} from '@cobuildlab/boost';
import {
  CompanyUserListResponse,
  FieldForm,
  CompanyUser,
} from '@cobuildlab/salezio-shared';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../../../shared/constants/colors';
import { fetchEndOfDayForms } from '../../reports-actions';
import { validateEndOfDayForm } from '../../reports-utils';

const Option = styled.button`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  margin-bottom: 3px;
  width: 100%;
  border-bottom: 1px solid var(--light-slate);
  &:hover {
    background-color: var(--primary);
    cursor: pointer;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormBody = ({
  companyUsers,
  form,
  setDisableSendReport,
  setForm,
  setTeamUserIdICE,
  setFormId,
  setFormName,
}: {
  companyUsers: CompanyUserListResponse;
  form: any;
  setDisableSendReport: (arg: boolean) => unknown;
  setForm: (arg: any) => unknown;
  setTeamUserIdICE: (arg: string | undefined) => unknown;
  setFormId: (arg: string) => unknown;
  setFormName: (arg: string) => unknown;
}): JSX.Element => {
  const [companySelected, setCompanySelected] = useState<string | undefined>(
    '',
  );
  const [forms, setForms] = useState<any>();
  const [formSelected, setFormSelected] = useState<any>();
  const [select, setSelect] = useState<any>({});

  useEffect(() => {
    if (!companySelected) return;
    (async () => {
      const {
        data: { endOfDayFormsList },
      } = await fetchEndOfDayForms(companySelected);

      setForms(endOfDayFormsList);
    })();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [companySelected]);

  useEffect(() => {
    if (companyUsers) {
      setCompanySelected(companyUsers.items[0].company?.id);
      setTeamUserIdICE(
        companyUsers.items[0].id ? companyUsers.items[0].id : '',
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  useEffect(() => {
    if (!formSelected) return;
    setFormName(formSelected?.name);
    const fieldsDefault = formSelected?.fields?.items?.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field: any) => field.default || field.type === 'CHECKBOX',
    );
    console.log(formSelected?.fields?.items);

    setFormId(formSelected.id);
    setForm(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fieldsDefault.reduce((acc: any, field: FieldForm) => {
        if (field?.type === 'CHECKBOX') {
          return {
            ...acc,
            [field?.name || '']: field.default === 'TRUE',
          };
        }
        return {
          ...acc,
          [field?.name || '']: field.default,
        };
      }, {}),
    );

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formSelected]);

  const isValid = formSelected
    ? validateEndOfDayForm(formSelected.fields, form)
    : false;

  useEffect(() => {
    setDisableSendReport(!isValid);
  }, [isValid, setDisableSendReport]);

  useEffect(() => {
    setForm((prevForm: any) => ({
      ...prevForm,
      ...select,
    }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [select]);

  const handleForm = (value: string | boolean, field: string): void => {
    setForm((prevForm: any) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  if (formSelected) {
    return (
      <Dialog.Body>
        <OptionsContainer>
          {formSelected?.fields?.items?.map((field: FieldForm) => (
            <Row justifyContent="between" style={{ marginBottom: 10 }}>
              <Text weight="bold" color={THEME_COLORS.DARK_SLATE}>
                {field.name}
                {field.required && (
                  <Text weight="bold" color="DANGER">
                    *
                  </Text>
                )}
              </Text>

              {field.type !== 'SELECT' ? (
                <InputField
                  style={
                    field.type !== 'CHECKBOX'
                      ? { maxWidth: 300 }
                      : { width: 20, height: 20 }
                  }
                  stretch
                  onChange={(e: any) => {
                    if (!field.name) return;
                    if (field.type !== 'CHECKBOX') {
                      Object.assign(field, { default: '' });
                      handleForm(e.target.value as string, field?.name);
                    } else {
                      handleForm(e.target.checked as boolean, field?.name);
                    }
                  }}
                  placeholder={field.type}
                  input={{
                    name: field.name,
                    type: field.type,
                    value: form[field?.name || ''] || field.default,
                    // checked: field.default === 'TRUE',
                  }}
                />
              ) : (
                <Select
                  value={select[field?.name || '']}
                  options={
                    field?.values?.map((option) => ({
                      value: option,
                      label: option,
                    })) || [{ label: '', value: '' }]
                  }
                  onChange={(value: any) =>
                    setSelect((prevSelect: any) => ({
                      ...prevSelect,
                      [field?.name || '']: value,
                    }))
                  }
                />
              )}
            </Row>
          ))}
        </OptionsContainer>
      </Dialog.Body>
    );
  }
  if (companySelected) {
    return (
      <OptionsContainer>
        {forms?.items?.map((formItem: any) => (
          <Option onClick={() => setFormSelected(formItem)}>
            <Text weight="bold" color={THEME_COLORS.DARK_SLATE}>
              {formItem.name}
            </Text>
          </Option>
        ))}
      </OptionsContainer>
    );
  }
  if (companyUsers) {
    return (
      <OptionsContainer>
        {companyUsers.items.map(({ company, id }: CompanyUser) => (
          <Option
            onClick={() => {
              setCompanySelected(company?.id);
              setTeamUserIdICE(id);
            }}
          >
            <Text weight="bold" color={THEME_COLORS.DARK_SLATE}>
              {company?.name}
            </Text>
          </Option>
        ))}
      </OptionsContainer>
    );
  }
  return <NoData />;
};

/* eslint-disable-next-line import/no-default-export */
export default FormBody;
