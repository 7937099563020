export const WORKSPACE_ID = process.env.REACT_APP_WORKSPACE_ID;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME || '';
export const WORKSPACE_ENDPOINT = `https://api.8base.com/${WORKSPACE_ID}${
  ENVIRONMENT_NAME.toLowerCase() === 'master' || ENVIRONMENT_NAME === ''
    ? ''
    : `_${ENVIRONMENT_NAME}`
}`;
export const AUTH_PROFILE_ID = process.env.REACT_APP_AUTH_PROFILE_ID || '';
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || '';
export const AUTH_CLIENT_DOMAIN =
  process.env.REACT_APP_AUTH_CLIENT_DOMAIN || '';
export const EIGHTBASE_WS_ENDPOINT = 'wss://ws.8base.com';

export const ENV_VARS_SETUP_IS_READY = ![
  AUTH_PROFILE_ID,
  WORKSPACE_ID,
  AUTH_CLIENT_DOMAIN,
  AUTH_CLIENT_ID,
].some((key) => key === '');

export const AUTH_CLIENT_REDIRECT_URI = `${window.location.origin}/auth/callback`;

export type ListInitialStateType<T> = {
  items: T[];
  count: number;
  loading: boolean;
};

export const LIST_INITIAL_STATE: ListInitialStateType<unknown> = {
  items: [],
  count: 0,
  loading: true,
};

export const SALESFORCE_CLIENT_ID =
  process.env.REACT_APP_SALESFORCE_CLIENT_ID || '';
export const SALESFORCE_REDIRECT_URI = `${window.location.origin}/salesforce/callback`;
