import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { Button, ButtonGroup } from '@cobuildlab/boost';

export const UnStyledButton = styled.button`
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: initial;
`;

export const LinkBtnCss = css`
  font-size: 12px;
  text-decoration: none;
  color: #000;
  margin-bottom: 8px;
  transition: color linear 0.4s;
  &:hover {
    color: var(--primary);
  }
`;

export const ButtonLink = styled(UnStyledButton)`
  ${LinkBtnCss}
  color: var(--primary);
  margin-bottom: 0;
`;

export const Link = styled(RouterLink)`
  --underline: transparent;
  color: var(--primary);
  text-decoration: none;
  transition: all linear 0.35s;
  position: relative;
  &:hover {
    color: var(--primary);
    --underline: var(--primary);
  }
  &::after {
    transition: inherit;
    border-top: 0.1em solid var(--underline);
    content: '';
    position: absolute;
    right: 0.1em;
    bottom: 0.2em;
    left: 0.1em;
  }
`;

/**
 * @param root0 - Props.
 * @param root0.options - Options.
 * @param root0.value - Selected value.
 * @param root0.onChange - On change handler.
 * @returns Tab buttons.
 */
export function TabButtons<T extends number | string>({
  options,
  value,
  onChange,
}: {
  options: {
    value: T;
    label: string;
  }[];
  value: T;
  onChange: (value: T) => void;
}): JSX.Element {
  return (
    <ButtonGroup>
      {options.map((item) => (
        <Button
          key={`${item.value}-${item.label}`}
          variant={item.value === value ? 'raised' : 'outlined'}
          onClick={() => {
            onChange(item.value);
          }}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
