import { createAction } from '@cobuildlab/react-simple-state';

import { OnBreabcrumbText, OnBreabcrumbTextError } from './breabcrumb-event';
import { BreabrumbLink } from './breabcrumb-type';

/**
 * @returns {string} Breabcrumb text.
 */
export const setBreabcrumbText = createAction(
  OnBreabcrumbText,
  OnBreabcrumbTextError,
  async (link?: BreabrumbLink[]) => link,
);
