import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createEvent } from '@cobuildlab/react-simple-state';
import { User } from '@cobuildlab/salezio-shared';

export const OnApolloClient = createEvent<
  ApolloClient<NormalizedCacheObject>
>();
export const OnSessionFetch = createEvent<{
  user: Partial<User>;
}>();
export const OnSessionError = createEvent<Error>();

export const OnToken = createEvent<string>();
export const OnTokenError = createEvent<Error>();
