import styled from 'styled-components';
import chevronIcon from '../assets/images/chevron.svg';

type IconProps = { right: string; rotate: string };

const Icon = styled.img<IconProps>`
  transform: rotate(${(props) => props.rotate});
  margin-left: 10px;
  position: absolute;
  right: ${({ right }) => right};
  top: 12px;
  width: 8px;
  height: 12px;
  transition: transform 0.2s ease-out;
`;
const DIRECTIONS = {
  UP: '-90deg',
  DOWN: '90deg',
};

interface ChevronProps {
  right?: string;
  direction?: keyof typeof DIRECTIONS;
}

/**
 * @param {object}props - Component props.
 * @param {string}props.direction - Direction of the chevron arrow.
 * @param {string}props.right - Right css prop.
 * @returns {JSX.Element} - Component.
 */
export function Chevron({
  direction = 'UP',
  right = '0px',
  ...props
}: ChevronProps): JSX.Element {
  return (
    <Icon
      rotate={DIRECTIONS[direction]}
      right={right}
      src={chevronIcon}
      alt="arrow-icon"
      {...props}
    />
  );
}
