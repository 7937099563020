import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { TeamManagementMenu } from './TeamManagementMenu';

const SideBarContainer = styled.div`
  width: 300px;
  height: 100%;
  border-right: 1px solid #d8d8d8;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
`;

/**
 *  Dashoard View for App.
 *
 * @returns {ReactElement} - React element.
 */
export function TeamManagementSideBar(): ReactElement {
  return (
    <SideBarContainer>
      <TeamManagementMenu />
    </SideBarContainer>
  );
}
