/* eslint-disable @typescript-eslint/no-explicit-any */
import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { useRoleAuth } from '../utils/hooks';

type RoleAuthorizationProps = {
  children: ((message: string) => React.ReactNode) | React.ReactNode;
  error?: ((message: string) => React.ReactNode) | React.ReactNode;
  permission?: PermissionsNames;
  extraData?: Record<any, any>;
};
/**
 * @param {object} props - Props.
 * @param {JSX.Element} props.children - Children.
 * @param {PermissionsNames} props.permission - Permission to eject.
 * @param {JSX.Element} props.error - Opcional render.
 * @param {Record<any, any>} props.extraData - Additional data for permission.
 * @returns {JSX.Element} Props.children - props.
 * @example
 *
 * return (
 *   <RoleAuthorization  permission={PermissionsNames.DASHBOARD_VIEW}>
 *     <ChildrenComponent/>
 *   </RoleAuthorization>
 * )
 * or...
 * return (
 *   <RoleAuthorization  permission={PermissionsNames.DASHBOARD_VIEW}>
 *     {(message)=> <ChildrenComponent/>}
 *   </RoleAuthorization>
 * )
 * or...
 * return (
 *   <RoleAuthorization  permission={PermissionsNames.DASHBOARD_VIEW}
 *    error={(message)=> console.log(error)}>
 *     {(message)=> <ChildrenComponent/>}
 *
 *   </RoleAuthorization>
 * )
 *
 */
export function RoleAuthorization({
  children,
  error,
  extraData,
  permission = PermissionsNames.DASHBOARD_VIEW,
}: RoleAuthorizationProps): JSX.Element | null {
  const [canRender, message] = useRoleAuth(permission, extraData);
  // Use reder props pattern to pass loading state to children, if is needed
  const render = typeof children === 'function' ? children(message) : children;
  if (canRender) {
    return render;
  }

  return typeof error === 'function' ? error(message) : null;
}
