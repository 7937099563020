import styled from 'styled-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { ROUTES } from '../../../shared/constants/menu-routes';
import { useRoleAuth } from '../../../shared/utils/hooks';

const MenuContainer = styled.nav`
  width: 100%;
  grid-area: menu;
`;
type MenuLinkProps = {
  active?: boolean;
};
const Link = styled(RouterLink)<MenuLinkProps>`
  height: 32px;
  padding: 5px 20px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${(props) => (props.active ? 'var(--primary)' : 'initial')};
  text-decoration: none;
`;
type LiProps = {
  active?: boolean;
  disabled?: boolean;
};
const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li<LiProps>`
  background-color: ${(props) => (props.active ? 'white' : 'initial')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0.5px;
    left: 0px;
    width: 4px;
    height: 32px;
    border-radius: 10px;
    background-color: ${(props) =>
      props.active ? 'var(--primary)' : 'initial'};
  }
  &:hover {
    background-color: white;
    &::before {
      background-color: var(--primary);
    }
  }
`;

/**
 * @param root0 - Props.
 * @param root0.path - Path.
 * @param root0.currentPath - Currenpath.
 * @param root0.name - Name.
 * @param root0.permission - Permission.
 * @returns List link component.
 */
function ListLink({
  currentPath,
  path,
  name,
  permission,
}: {
  path: string;
  currentPath: string;
  name: string;
  permission: PermissionsNames;
}): JSX.Element {
  const [cantView] = useRoleAuth(permission, undefined);
  return cantView ? (
    <Li key={path} active={currentPath === path}>
      <Link to={path} active={currentPath === path}>
        <span>{name}</span>
      </Link>
    </Li>
  ) : (
    <></>
  );
}

/**
 * Settings Menu component.
 *
 * @returns {JSX.Element} - Component.
 */
export function TeamManagementMenu(): JSX.Element {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <MenuContainer aria-label="navigation">
      <List>
        {Object.values(ROUTES.TEAM_MANAGEMENT.internalPaths || {}).map(
          (route) => (
            <ListLink
              currentPath={currentPath}
              path={route?.path ?? ''}
              name={route?.name ?? ''}
              permission={route?.permission || PermissionsNames.USER_EDIT}
            />
          ),
        )}
      </List>
    </MenuContainer>
  );
}
