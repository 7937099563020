import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createAction } from '@cobuildlab/react-simple-state';
import { AUTH_PROFILE_ID, WORKSPACE_ENDPOINT } from '../../../shared/constants';
import { OnToken } from '../auth-events';
import {
  OnHandleAuth,
  OnHandleAuthError,
  OnResendVerificationEmail,
  OnResendVerificationEmailError,
} from './auth-callback-events';
import {
  CURRENT_USER_QUERY,
  USER_SIGN_UP_MUTATION,
  USER_LOGIN_UPDATE,
} from './auth-callback-queries';

/**
 * @param {Object} client - Apollo client to make the request.
 * @param {Object} user - User to query or save to database.
 * @returns {boolean}  - Boolean true if the user exist, false if not nad have to created.
 */
export const handleAuthentication = createAction(
  OnHandleAuth,
  OnHandleAuthError,
  async (
    client: ApolloClient<NormalizedCacheObject>,
    user,
    invited: boolean,
  ): Promise<boolean> => {
    const namespace = 'https://salezio.8base.com/';
    const { email } = user;

    const userData = {
      email,
      firstName: '',
      lastName: '',
      shouldCreateCompany: !invited,
    };

    const firstName = user[`${namespace}first_name`];

    if (firstName) userData.firstName = firstName;

    const lastName = user[`${namespace}last_name`];

    if (firstName) userData.lastName = lastName;

    try {
      console.log('token: ', OnToken.get());

      await client.query({
        query: CURRENT_USER_QUERY,
      });

      try {
        await client.mutate({
          mutation: USER_LOGIN_UPDATE,
          variables: {
            userEmail: email,
          },
        });
      } catch (err) {
        console.error({ err });
      }
      return true;
    } catch (error) {
      if (invited) localStorage.removeItem('invited');
      await client.mutate({
        mutation: USER_SIGN_UP_MUTATION,
        variables: {
          user: userData,
          authProfileId: AUTH_PROFILE_ID,
        },
      });

      return false;
    }
  },
);
/**
 */
export const resendEmail = createAction(
  OnResendVerificationEmail,
  OnResendVerificationEmailError,
  async (userId: string) => {
    const response = await fetch(`${WORKSPACE_ENDPOINT}/webhook/resend-email`, {
      body: JSON.stringify({ userId }),
      method: 'POST',
    });
    return (await response.json()).message;
  },
);
