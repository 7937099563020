import React from 'react';
import { Dropdown, Card, Row, Heading } from '@cobuildlab/boost';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { Icon } from '../../shared/icons/Icon';
import { customScrollBar } from '../../shared/css/scrollbar';
import { IconCount } from '../../shared/components/IconCount';
import { useSession } from '../session/session-hooks';
import { NotificationsListView } from './components/NotificationsListView';
import { useNotificationsList } from './notifications-hooks';
import { onUpdateManyNotificationsErrorEvent } from './notifications-events';

const NotificationCard = styled(Card)`
  width: 600px;
`;
const Body = styled(Card.Body)`
  min-height: 300px !important;
  max-height: calc(100vh - 200px) !important;
  ${customScrollBar}
`;
const NoNotification = styled.div`
  width: 100%;
  min-height: 300px !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * @returns {React.FunctionComponent} - Notifications component.
 */
export function Notifications(): JSX.Element {
  const session = useSession();
  const {
    today,
    older,
    count,
    totalCount,
    refetch,
    readNotificationsAction,
  } = useNotificationsList(session.user.id, session.selectedCompany.id);
  useSubscription(onUpdateManyNotificationsErrorEvent, () => {
    toast.error('Error when trying to set as read notifications');
  });
  return (
    <Dropdown defaultOpen={false}>
      <Dropdown.Head stretch>
        {({ toggleDropdown }) => (
          <IconCount
            onClick={(e: React.MouseEvent) => {
              toggleDropdown();
              e.stopPropagation();
            }}
            alt="Notifications"
            count={count}
            iconName="notifications"
            style={{
              width: '25px',
              height: '30px',
            }}
            countStyle={{
              right: '-8px',
              top: '-8px',
              width: '20px',
              height: '20px',
              textAlign: 'center',
              borderRadius: '50%',
              color: 'var(--white)',
              zIndex: -1,
              background: 'hsla(345, 100%, 50%, .8)',
            }}
            noCountMessage="There are no more new notifications"
          />
        )}
      </Dropdown.Head>
      <Dropdown.Body background="white" pin="right" offset="lg">
        {({ toggleDropdown }) => (
          <NotificationCard>
            <Card.Header>
              <Row justifyContent="between" alignItems="center" stretch>
                <Heading type="h3">Notifications</Heading>
              </Row>
            </Card.Header>
            <Body>
              {totalCount > 0 ? (
                <>
                  <NotificationsListView
                    title="Today"
                    data={today}
                    toggle={toggleDropdown}
                    refetch={refetch}
                    readNotificationsAction={readNotificationsAction}
                  />
                  <NotificationsListView
                    title="Older"
                    data={older}
                    toggle={toggleDropdown}
                    refetch={refetch}
                    readNotificationsAction={readNotificationsAction}
                  />
                </>
              ) : (
                <NoNotification>
                  <Icon icon="noNotifications" />
                </NoNotification>
              )}
            </Body>
          </NotificationCard>
        )}
      </Dropdown.Body>
    </Dropdown>
  );
}
