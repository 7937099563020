import { gql } from '@apollo/client';
import { CompanyUserFragment } from '../session/session-queries';

const UserFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    cellPhone
    workPhone
    workPhoneExt
    timezone
    email
    status

    callerPhoneCallRelation(
      filter: {
        OR: [
          { status: { equals: "answered" } }
          { status: { equals: "completed" } }
        ]
      }
    ) {
      items {
        duration
      }
    }
    lastLogIn {
      createdAt
    }
    shouldCreateCompany
    shouldSendInvitations
    avatar {
      downloadUrl
    }
    selectedCompany {
      id
      createdBy {
        id
      }
    }
    userTeamUserRelation {
      count
      items {
        id
        role
        enable
        team {
          id
          name
          company {
            id
          }
        }
      }
    }
    userCompanyUserRelation {
      items {
        ...CompanyUserFragment
      }
    }
  }
  ${CompanyUserFragment}
`;
export const FETCH_USERS = gql`
  query($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(
      filter: $filter
      first: $first
      skip: $skip
      groupBy: {
        query: {
          userTeamUserRelation: {
            id: { as: "count", fn: { aggregate: COUNT } }
          }
        }
      }
    ) {
      count
      items {
        ...UserFragment
      }
      groups {
        count: Int
      }
    }
  }
  ${UserFragment}
`;
export const COMPANY_FORMS_QUERY = gql`
  query($id: ID!) {
    company(id: $id) {
      companyEndOfDayFormRelation {
        count
        items {
          name
          id
          default
          time
          endOfDayReport {
            count
          }
          fields {
            count
            items {
              id
              name
              default
              required
              values
              type
            }
          }
        }
      }
      companyNoteFormRelation {
        count
        items {
          name
          id
          default
          time
          sales
          noteFormNoteReportRelation {
            count
          }
          fields {
            count
            items {
              id
              name
              default
              required
              values
              type
            }
          }
        }
      }
    }
  }
`;
export const CREATE_TEAM = gql`
  mutation($data: TeamCreateInput!) {
    teamCreate(data: $data) {
      name
      id
      company {
        id
      }
    }
  }
`;
export const UPDATE_TEAM = gql`
  mutation($data: TeamUpdateInput!) {
    teamUpdate(data: $data) {
      name
      id
      company {
        id
      }
    }
  }
`;
export const FETCH_INVITATIONS_TEAM = gql`
  query($filter: InvitationFilter, $first: Int, $skip: Int) {
    invitationsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        email
        role
        team {
          id
          name
        }
        companyName
        company {
          id
        }
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation($data: UserUpdateInput!) {
    userUpdate(data: $data, force: true) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const UPDATE_TEAM_USER = gql`
  mutation($data: TeamUserUpdateInput!) {
    teamUserUpdate(data: $data, force: true) {
      id
      role
      enable
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;
export const FETCH_TEAMS = gql`
  query($filter: TeamFilter!, $first: Int, $skip: Int) {
    teamsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        teamTeamUserRelation {
          count
          items {
            enable
            id
            user {
              ...UserFragment
            }
          }
        }
        teamInvitationRelation {
          items {
            id
            email
            status
          }
        }
        name
        id
        company {
          id
        }
      }
    }
  }
  ${UserFragment}
`;

export const SEND_INVITATION = gql`
  mutation($data: InvitationCreateInput!) {
    invitationCreate(data: $data) {
      email
      team {
        id
      }
      role
      company {
        id
      }
    }
  }
`;
export const UPDATE_INVITATION = gql`
  mutation($data: InvitationUpdateInput!) {
    invitationUpdate(data: $data) {
      email
      team {
        id
      }
      role
      company {
        id
      }
    }
  }
`;
export const DELETE_COMPANY_USER = gql`
  mutation($data: CompanyUserDeleteInput) {
    companyUserDelete(data: $data, force: true) {
      success
    }
  }
`;
export const DELETE_TEAM = gql`
  mutation($data: TeamDeleteInput) {
    teamDelete(data: $data, force: true) {
      success
    }
  }
`;
export const DEL_TEAM_RES = gql`
  mutation($id: ID!) {
    deleteATeam(teamId: $id) {
      success
    }
  }
`;
export const DELETE_TEAM_USER = gql`
  mutation($data: TeamUserDeleteInput) {
    teamUserDelete(data: $data, force: true) {
      success
    }
  }
`;
export const FETCH_NOTE_FORMS = gql`
  query($filter: NoteFormFilter!) {
    noteFormsList(filter: $filter) {
      count
      items {
        name
        time
        sales
        fields {
          count
          items {
            name
            type
            values
            default
            required
          }
        }
      }
    }
  }
`;
export const FETCH_END_OF_DAY_FORMS = gql`
  query($filter: EndOfDayFormFilter!) {
    endOfDayFormsList(filter: $filter) {
      count
      items {
        id
        name
        time
        fields {
          count
          items {
            name
            type
            values
            default
            required
          }
        }
      }
    }
  }
`;
export const FETCH_END_OF_DAY_FORMS_ALL = gql`
  query($filter: EndOfDayFormFilter) {
    endOfDayFormsList(filter: $filter) {
      count
      items {
        id
        name
        time
        fields {
          count
          items {
            name
            type
            values
            default
            required
          }
        }
      }
    }
  }
`;
export const UPDATE_NOTE_FORMS = gql`
  mutation($data: NoteFormUpdateInput!) {
    noteFormUpdate(data: $data, force: true) {
      name
      time
      sales
      fields {
        count
        items {
          name
          type
          values
          default
          required
        }
      }
    }
  }
`;
export const UPDATE_END_OF_DAY_FORMS = gql`
  mutation($data: EndOfDayFormUpdateInput!) {
    endOfDayFormUpdate(data: $data, force: true) {
      name
      time
      fields {
        count
        items {
          name
          type
          values
          default
          required
        }
      }
    }
  }
`;
export const CREATE_NOTE_FORMS = gql`
  mutation($data: NoteFormCreateInput!) {
    noteFormCreate(data: $data) {
      name
      time
      sales
      fields {
        count
        items {
          name
          type
          values
          default
          required
        }
      }
    }
  }
`;
export const CREATE_END_OF_DAY_FORMS = gql`
  mutation($data: EndOfDayFormCreateInput!) {
    endOfDayFormCreate(data: $data) {
      name
      time
      fields {
        count
        items {
          name
          type
          values
          default
          required
        }
      }
    }
  }
`;
export const DELETE_NOTE_FORM = gql`
  mutation($data: NoteFormDeleteInput!) {
    noteFormDelete(data: $data, force: true) {
      success
    }
  }
`;
export const DELETE_END_OF_DAY_FORM = gql`
  mutation($data: EndOfDayFormDeleteInput!) {
    endOfDayFormDelete(data: $data, force: true) {
      success
    }
  }
`;
export const FETCH_TEAM_STATS = gql`
  query($companyId: ID, $teamId: ID) {
    teamStats(companyId: $companyId, teamId: $teamId) {
      usersStats {
        name
        rank
        sales
        minutesConsumed
      }
      teamsStats {
        name
        totalSales
        leads
        sprints
      }
    }
  }
`;

export const COMPANY_USERS_QUERY = gql`
  query($id: ID!) {
    usersList(
      filter: {
        userCompanyUserRelation: {
          some: { company: { id: { equals: $id } }, role: { equals: "MEMBER" } }
        }
      }
    ) {
      items {
        email
        firstName
        lastName
      }
    }
  }
`;
