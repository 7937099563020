import { PermissionsNames } from '@cobuildlab/salezio-shared';
import { Icons } from '../icons/Icons';

export type InternalRoutesOptionType = {
  name: string;
  path: string;
  pathWithParam?: string;
  permission?: PermissionsNames;
};

export type InternalOptionType = {
  [key in InternalRoutesKeys]?: InternalRoutesOptionType;
};

export type RoutesOptionType = {
  name: string;
  path: string;
  internalPaths?: InternalOptionType;
  icon: keyof typeof Icons;
  permission?: PermissionsNames;
};

export type RouteType = { [key in RoutesKeys]: RoutesOptionType };

export enum RoutesKeys {
  DASHBOARD = 'DASHBOARD',
  SPRINT_MANAGEMENT = 'SPRINT_MANAGEMENT',
  SPRINT_ACTIVITY = 'SPRINT_ACTIVITY',
  SETTINGS = 'SETTINGS',
  TEAM_MANAGEMENT = 'TEAM_MANAGEMENT',
  REPORTS = 'REPORTS',
}

export enum InternalRoutesKeys {
  SETTINGS_TEAM = 'SETTINGS_TEAM',
  TEAM_MANAGEMENT_TEAM = 'TEAM_MANAGEMENT_TEAM',
  TEAM_MANAGEMENT_USERS = 'TEAM_MANAGEMENT_USERS',
  TEAM_MANAGEMENT_ADMIN = 'TEAM_MANAGEMENT_ADMIN',
  SETTINGS_GENERAL = 'SETTINGS_GENERAL',
  SETTINGS_BILLING = 'SETTINGS_BILLING',
  SETTINGS_SALESFORCE = 'SETTINGS_SALESFORCE',
  NEW_SMART_SPRINT = 'NEW_SMART_SPRINT',
  NEW_STATIC_SPRINT = 'NEW_STATIC_SPRINT',
  PRE_DIALER_SPRINT = 'PRE_DIALER_SPRINT',
  LEAD_INFORMATION_SPRINT = 'LEAD_INFORMATION_SPRINT',
  SETTINGS_PROFILE = 'SETTINGS_PROFILE',
  GOALS_SETTINGS = 'GOALS_SETTINGS',
  GOALS_REPORT = 'GOALS_REPORT',
  END_OF_DAY_REPORT = 'END_OF_DAY_REPORT',
  NOTE_REPORT = 'NOTE_REPORT',
  PHONE_NUMBERS = 'PHONE_NUMBERS',
  SPRINT = 'SPRINT',
  CALLS = 'CALLS',
  LEAD = 'LEAD',
  SETTINGS_FORMS = 'SETTINGS_FORMS',
  NEW_REPORT_SPRINT = 'NEW_REPORT_SPRINT',
}

export const ROUTES: RouteType = {
  DASHBOARD: {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    permission: PermissionsNames.DASHBOARD_VIEW,
  },
  SPRINT_ACTIVITY: {
    name: 'Sprint Activity',
    path: '/sprint-activity',
    icon: 'sprint',
    internalPaths: {
      SPRINT: {
        name: 'Sprint Detail',
        path: '/sprint-activity/sprint',
        pathWithParam: ':sprintActivityId',
      },
      LEAD: {
        name: 'Lead Information',
        path: '/sprint-activity/lead',
        pathWithParam: ':id',
      },
      CALLS: {
        name: 'Sprint Calls',
        path: '/sprint-activity/calls',
        pathWithParam: ':sprintActivityId',
      },
    },
    permission: PermissionsNames.SPRINT_VIEW,
  },
  SPRINT_MANAGEMENT: {
    name: 'Sprint configuration',
    path: '/sprint-configuration',
    icon: 'sprint-config',
    permission: PermissionsNames.SPRINT_VIEW,
    internalPaths: {
      NEW_REPORT_SPRINT: {
        name: 'New Report Sprint Configuration',
        path: '/sprint-configuration/new-report-sprint-configuration',
      },
      NEW_STATIC_SPRINT: {
        name: 'New Static Sprint Configuration',
        path: '/sprint-configuration/new-static-sprint-configuration',
      },
      NEW_SMART_SPRINT: {
        name: 'New Dynamic Sprint Configuration',
        path: '/sprint-configuration/new-dynamic-sprint-configuration',
      },
      PRE_DIALER_SPRINT: {
        name: 'Pre Dialer for Sprints',
        path: '/sprint-configuration/pre-dialer-sprint/:sprintId',
      },
      LEAD_INFORMATION_SPRINT: {
        name: 'Lead Management',
        path: '/sprint-configuration/lead-preview',
      },
    },
  },
  REPORTS: {
    name: 'Reports',
    path: '/reports',
    icon: 'performance',
    permission: PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW,
    internalPaths: {
      END_OF_DAY_REPORT: {
        name: 'End of Day Report',
        path: '/reports/end-of-day-report',
        permission: PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW,
      },
      NOTE_REPORT: {
        name: 'Note Report',
        path: '/reports/note-report',
        permission: PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW,
      },
      GOALS_REPORT: {
        name: 'Goals Report',
        permission: PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW,
        path: '/reports/goals-report',
      },
    },
  },
  TEAM_MANAGEMENT: {
    name: 'Users Management',
    path: '/users-management',
    icon: 'invitations',
    permission: PermissionsNames.TEAM_MANAGEMENT_VIEW,
    internalPaths: {
      TEAM_MANAGEMENT_USERS: {
        name: 'Users',
        path: '/users-management/users',
        permission: PermissionsNames.TEAM_MANAGEMENT_USER_VIEW,
      },
      TEAM_MANAGEMENT_TEAM: {
        name: 'Team',
        path: '/users-management/team',
        permission: PermissionsNames.TEAM_MANAGEMENT_TEAM_VIEW,
      },
      TEAM_MANAGEMENT_ADMIN: {
        name: 'Admin',
        path: '/users-management/admin',
        permission: PermissionsNames.COMPANY_EDIT,
      },
    },
  },
  SETTINGS: {
    name: 'Settings',
    path: '/settings',
    icon: 'settings',
    permission: PermissionsNames.DASHBOARD_VIEW,
    internalPaths: {
      SETTINGS_PROFILE: {
        name: 'Profile',
        path: '/settings/profile',
      },
      SETTINGS_GENERAL: {
        name: 'Company Edit',
        path: '/settings/company-edit',
        permission: PermissionsNames.COMPANY_EDIT,
      },
      SETTINGS_BILLING: {
        name: 'Billing',
        permission: PermissionsNames.COMPANY_EDIT,
        path: '/settings/billing',
      },
      SETTINGS_SALESFORCE: {
        name: 'Salesforce',
        permission: PermissionsNames.COMPANY_EDIT,
        path: '/settings/salesforce',
      },
      SETTINGS_FORMS: {
        name: 'Company Forms',
        permission: PermissionsNames.DASHBOARD_ADMIN_VIEW,
        path: '/settings/forms',
      },
      PHONE_NUMBERS: {
        name: 'Phone Numbers',
        path: '/settings/phone-numbers',
      },
    },
  },
};

export const FIXED_ROUTES = {
  ONBOARDING: '/onboarding',
  AUTH: '/auth',
  REGISTER: '/register',
  AUTH_CALLBACK: '/auth/callback',
  LOGOUT: '/logout',
  SALESFORCE: '/salesforce/callback',
  TEAM_DETAILS: '/users-management/team/:team',
  INVITATIONS: '/invitations',
};

export const routes = Object.values(ROUTES);
