import { useEffect } from 'react';
import { InternalRoutesKeys, RoutesKeys } from '../../constants/menu-routes';
import { setBreabcrumbText } from './breabcrumb-action';
import { routeToBreabcrumb } from './breabcrumb-utils';

/**
 * @param {RoutesKeys | InternalRoutesKeys} routes - Routes to map the breadcrums.
 */
export function useBreadcrumb(routes: RoutesKeys | InternalRoutesKeys): void {
  useEffect(() => {
    setBreabcrumbText(routeToBreabcrumb(routes));
    return () => {
      setBreabcrumbText();
    };
  }, [routes]);
}
