import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Dropdown, Grid, Row, Text } from '@cobuildlab/boost';
import { check, PermissionsNames } from '@cobuildlab/salezio-shared';
// import { check } from 'prettier';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
/* eslint-disable-next-line import/no-named-as-default */
/* eslint-disable-next-line import/no-named-as-default-member */
import EndOfDayForm from '../../modules/reports/end-of-day-form/EndOfDayForm';
import { useSession } from '../../modules/session/session-hooks';
import { LinkBtnCss, UnStyledButton } from './ui/buttons/Buttons';

const StyledLink = styled(Link)`
  ${LinkBtnCss}
`;
const StyledButton = styled(UnStyledButton)`
  ${LinkBtnCss}
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d0d7dd;
  padding: 16px;
`;

const Body = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
`;

/**
 * Component that shows the avatar, user profile info and logout button.
 *
 * @returns {JSX.Element} - User profile component.
 */
export function UserProfile(): JSX.Element {
  const [isOpenEndOfDay, setIsOpenEndOfDay] = useState(false);
  const { logout } = useAuth0();
  /**
   * Function to logout from the app.
   *
   * @returns {void} Logout handler.
   */
  const handleLogout = (): void =>
    logout({
      returnTo: window.location.origin,
    });

  const session = useSession();
  const { user, selectedCompany } = session;
  const [canViewUserWidget] = check(
    selectedCompany.role,
    PermissionsNames.END_OF_DAY,
  );

  return (
    <Dropdown defaultOpen={false}>
      {/* Here in the head will work as a button to display the dropdown */}
      <Dropdown.Head stopClickPropagation>
        {({ toggleDropdown }) => (
          <>
            <UnStyledButton
              aria-label="user profile"
              onClick={(e) => {
                toggleDropdown();
                e.stopPropagation();
              }}
            >
              <Avatar
                size="sm"
                {...(user?.avatar
                  ? { src: user.avatar.downloadUrl }
                  : {
                      src:
                        'https://media.istockphoto.com/vectors/no-image-available-picture-vector-id1287465364?b=1&k=6&m=1287465364&s=612x612&w=0&h=b2nOkYGCW8kzAix6rWIWZCjKSYjlq57DdB_43SgB__4=',
                    })}
              />
            </UnStyledButton>
          </>
        )}
      </Dropdown.Head>
      {/* This body will render when the dropdwon is open */}
      <Dropdown.Body
        offset="sm"
        background="white"
        pin="right"
        closeOnClickOutside
      >
        <Header>
          <Grid.Layout inline columns="auto auto">
            <Row alignItems="center">
              <Avatar
                size="lg"
                firstName={user.firstName}
                lastName={user.lastName}
                src={
                  user?.avatar?.downloadUrl ||
                  'https://media.istockphoto.com/vectors/no-image-available-picture-vector-id1287465364?b=1&k=6&m=1287465364&s=612x612&w=0&h=b2nOkYGCW8kzAix6rWIWZCjKSYjlq57DdB_43SgB__4='
                }
                style={{
                  width: '60px',
                  height: '60px',
                  marginRight: '15px',
                }}
              />
            </Row>
            <Grid.Box style={{ justifyContent: 'center' }}>
              <Text weight="bold">
                {user.firstName} {user.lastName}
              </Text>
              <Text color="GRAY_50" kind="overline-1">
                {selectedCompany.role}
              </Text>
              <Text kind="body">{selectedCompany.name}</Text>
              <Text color="PRIMARY">{user.email}</Text>
            </Grid.Box>
          </Grid.Layout>
        </Header>
        <Body>
          {canViewUserWidget && (
            <StyledButton onClick={() => setIsOpenEndOfDay(true)}>
              End of Day report
            </StyledButton>
          )}
          <StyledLink to="/settings">My Account</StyledLink>
          <StyledButton onClick={handleLogout}>Logout</StyledButton>
        </Body>
      </Dropdown.Body>
      <EndOfDayForm
        isOpen={isOpenEndOfDay}
        onClose={() => {
          setIsOpenEndOfDay(false);
        }}
      />
    </Dropdown>
  );
}
