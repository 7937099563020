import { CompanyRoleNames } from '@cobuildlab/salezio-shared';
import { toast } from 'react-toastify';
import { FIXED_ROUTES, ROUTES } from '../../shared/constants/menu-routes';
import { RouteRules, Session } from './session-types';

export const ROUTES_RULES: RouteRules<Session> = [
  {
    condition: ({ data, loadingInvitation }) =>
      data.user.shouldCreateCompany ||
      (data.user.userCompanyUserRelation?.items.length === 0 &&
        !loadingInvitation),
    redirect: FIXED_ROUTES.ONBOARDING,
  },
  {
    condition: ({ data }) => data.user.shouldSendInvitations,
    redirect: FIXED_ROUTES.INVITATIONS,
  },
  {
    condition: ({ location }) => location === '/',
    redirect: ROUTES.DASHBOARD.path,
  },
  {
    condition: ({ location, data }) =>
      location === FIXED_ROUTES.ONBOARDING &&
      data.user.userCompanyUserRelation?.items.length !== 0 &&
      !data.user.shouldCreateCompany,
    redirect: ROUTES.DASHBOARD.path,
  },
  {
    condition: ({ location, data }) =>
      location === FIXED_ROUTES.INVITATIONS && !data.user.shouldSendInvitations,
    redirect: ROUTES.DASHBOARD.path,
  },
  {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    condition: ({ location, data }) => {
      if (
        data.user.shouldCreateCompany ||
        !data.selectedCompany.disabled ||
        location === ROUTES.SETTINGS.internalPaths?.SETTINGS_BILLING?.path ||
        location === ROUTES.SETTINGS.internalPaths?.SETTINGS_PROFILE?.path ||
        data.selectedCompany.role !== CompanyRoleNames.ADMIN
      ) {
        return false;
      }

      toast.warning(
        // eslint-disable-next-line @typescript-eslint/quotes
        "This company is disabled, you can't access this route.",
      );
      return true;
    },
    redirect: ROUTES.SETTINGS.internalPaths?.SETTINGS_BILLING?.path ?? '',
  },
  {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    condition: ({ location, data, loadingInvitation }) => {
      if (
        data.user.shouldCreateCompany ||
        (data.user.userCompanyUserRelation?.items.length === 0 &&
          !loadingInvitation) ||
        !data.selectedCompany.disabled ||
        location === ROUTES.SETTINGS.internalPaths?.SETTINGS_BILLING?.path ||
        location === ROUTES.SETTINGS.internalPaths?.SETTINGS_PROFILE?.path
      ) {
        return false;
      }

      return true;
    },
    redirect: ROUTES.SETTINGS.internalPaths?.SETTINGS_PROFILE?.path ?? '',
  },
];
