import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@cobuildlab/boost';
import { Icon } from '../icons/Icon';
import { Icons } from '../icons/Icons';

const IconCountWrap = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const Span = styled.span`
  font-size: 14px;
  line-height: 28px;
`;
const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  object-fit: contain;
  max-width: 100%;
`;

type IconCountProps = {
  alt: string;
  count: number;
  countStyle: React.CSSProperties;
  onClick: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
  imgUrl?: string;
  iconName?: keyof typeof Icons;
  noCountMessage?: string;
};

export const IconCount = ({
  alt,
  imgUrl,
  iconName,
  countStyle,
  ...props
}: IconCountProps): JSX.Element => {
  const count = props.count ? (
    <Container style={countStyle}>
      <Span>{props.count}</Span>
    </Container>
  ) : null;

  const icon =
    imgUrl && imgUrl !== '' ? (
      <Image src={imgUrl} alt={alt} {...props} />
    ) : (
      <Icon icon={iconName ?? 'notifications'} size={18} {...props} />
    );

  return (
    <IconCountWrap>
      {count}
      {props.count > 0 || props.noCountMessage === '' ? (
        icon
      ) : (
        <Tooltip cursor="pointer" message={props.noCountMessage}>
          {icon}
        </Tooltip>
      )}
    </IconCountWrap>
  );
};
