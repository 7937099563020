import { createAction } from '@cobuildlab/react-simple-state';
import {
  Notification,
  NotificationUpdateInput,
  NotificationUpdateByFilterInput,
  NotificationFilter,
} from '@cobuildlab/salezio-shared';
import { apolloClient } from '../../shared/apollo';
import {
  onUpdateNotificationErrorEvent,
  onUpdateNotificationEvent,
  onReadNotifications,
  onReadNotificationsError,
} from './notifications-events';
import {
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_BY_FILTER,
} from './notifications-queries';

/**
 * @param {object} data - Data to update in db.
 * @param {string} id - Id of the record.
 * @returns {Promise<{notificationUpdate:{id:string}}>} - Object with the updated data.
 */
export async function updateNotification(
  data: NotificationUpdateInput,
  id: string,
): Promise<{ notificationUpdate: Notification }> {
  const client = apolloClient;

  const variables = {
    data,
    id,
  };

  let response;

  try {
    response = await client.mutate({
      mutation: UPDATE_NOTIFICATION,
      variables,
    });
  } catch (e) {
    onUpdateNotificationErrorEvent.dispatch(e);
    console.log(e);
    throw e;
  }

  onUpdateNotificationEvent.dispatch(response.data);

  return response.data;
}

export const readNotifications = createAction(
  onReadNotifications,
  onReadNotificationsError,
  async (list: Notification[]) => {
    const variables: {
      data: NotificationUpdateByFilterInput;
      filter: NotificationFilter;
    } = {
      data: { isReaded: [{ set: true }] },
      filter: { OR: list.map((item) => ({ id: { equals: item.id } })) },
    };

    const response = await apolloClient.mutate({
      mutation: UPDATE_NOTIFICATION_BY_FILTER,
      variables,
    });
    apolloClient.cache.reset();
    return response.data.notificationUpdateByFilter;
  },
);
