import styled from 'styled-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Tooltip } from '@cobuildlab/boost';
import { Icon } from '../icons/Icon';
import { routes } from '../constants/menu-routes';
import { RoleAuthorization } from './RoleAuthorization';

const MenuContainer = styled.nav`
  height: calc(100vh);
  width: 56px;
  background-color: var(--dark-slate);
  grid-area: menu;
`;
type MenuLinkProps = {
  active?: boolean;
};
const Link = styled(RouterLink)<MenuLinkProps>`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
type LiProps = {
  active?: boolean;
  disabled?: boolean;
};
const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li<LiProps>`
  background-color: ${(props) => (props.active ? 'var(--primary)' : 'initial')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0.5px;
    left: 0px;
    width: 4px;
    height: 55px;
    border-radius: 10px;
    background-color: ${(props) => (props.active ? 'white' : 'initial')};
  }
  &:not(:first-of-type):hover {
    background-color: var(--primary);
    &::before {
      background-color: white;
    }
  }
`;
const StyledTooltip = styled(Tooltip)`
  display: block !important;
`;
/**
 * Main Menu component.
 *
 * @returns {JSX.Element} - Component.
 */
export function Menu(): JSX.Element {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <MenuContainer aria-label="navigation">
      <List>
        <Li>
          <Link to="/">
            <Icon icon="salezio" />
          </Link>
        </Li>
        {routes.map((route) => (
          <RoleAuthorization permission={route.permission} key={route.path}>
            <Li active={currentPath.includes(route.path)}>
              <StyledTooltip
                trigger="hover"
                placement="right"
                message={route.name}
              >
                <Link to={route.path}>
                  <Icon icon={route.icon} color="#FFFFFF" />
                </Link>
              </StyledTooltip>
            </Li>
          </RoleAuthorization>
        ))}
      </List>
    </MenuContainer>
  );
}
