import { toast } from 'react-toastify';

declare const window: any; // eslint-disable-line  @typescript-eslint/no-explicit-any

type ToastType = 'success' | 'info' | 'warn' | 'error';

/**
 * Initiates window.toast method which is used in serviceWorker.
 */
export function initWindowToast(): void {
  window.toast = (message: string, type: ToastType = 'info') => toast[type](message);
}
