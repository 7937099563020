import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on User {
    email
    firstName
    id
    lastName
    cellPhone
    workPhone
    workPhoneExt
    timezone
    onboardingStep
    avatar {
      id
      downloadUrl
      previewUrl
    }
    userCompanyUserRelation {
      items {
        id
        role
        company {
          name
          id
          status
          taxId
        }
      }
    }
  }
`;

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    status
    companyTeamRelation {
      items {
        id
        name
      }
    }
    companySubscriptionHistoryRelation(first: 1, orderBy: createdAt_DESC) {
      items {
        id
        status
        stripeCustomerId
        stripeSubscriptionId
        expireDate
        pendingUpdate
        plan {
          id
          name
          description
          isFreeTrial
          stripeId
          planPlanPriceRelation {
            items {
              id
              name
              type
              price
              stripeId
            }
          }
        }
      }
    }
    companyCompanyInvoiceRelation {
      items {
        id
        invoiceId
        status
        paymentIntentStatus
        paymentMethodId
      }
    }
    companyPaymentMethodRelation(first: 1, orderBy: createdAt_DESC) {
      items {
        id
        cardHolderName
        expYear
        expMonth
        addressLine1
        addressLine2
        city
        state
        stripeId
      }
    }
  }
`;

export const USER_QUERY = gql`
  query User($id: ID) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UPDATE_USER = gql`
  mutation($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
    }
  }
`;

export const SESSION_QUERY = gql`
  query($id: ID) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
