import { useCallback, useEffect, useState } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  Notification,
  NotificationListResponse,
  NotificationPayload,
  Writeable,
} from '@cobuildlab/salezio-shared';
import {
  QUERY_NOTIFICATION,
  SUBSCRIPTION_NOTIFICATION,
} from './notifications-queries';
import {
  mapNotificationsList,
  MappedNotificationsList,
} from './notifications-utils';
import { readNotifications } from './notifications-actions';

type NotificationListHookResponse = {
  list: Notification[];
  today: Notification[];
  older: Notification[];
  loading: boolean;
  readNotificationsAction: () => void;
  refetch: (
    variables?:
      | Partial<{
          user: string;
          company: string;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<unknown>>;
  totalCount: number;
  count: number;
};

/**
 * @param {string} user - List of notifications.
 * @param {string} company - List of notifications.
 * @returns {NotificationListHookResponse} - Maped object with today and older reduced list.
 */
export function useNotificationsList(
  user: string,
  company: string,
): NotificationListHookResponse {
  const { data, loading, subscribeToMore, refetch } = useQuery<{
    notificationsList: NotificationListResponse;
  }>(QUERY_NOTIFICATION, {
    variables: { user, company },
  });
  const [list, setList] = useState<Notification[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [{ today, older }, setMapped] = useState<MappedNotificationsList>(
    mapNotificationsList([]),
  );
  const [callReadNotifications] = useCallAction(readNotifications, {
    onCompleted() {},
    onError() {},
  });
  const readNotificationsAction = useCallback(() => {
    callReadNotifications(list);
  }, [list, callReadNotifications]);
  useEffect(() => {
    setList(
      data ? (data.notificationsList.items as Writeable<Notification[]>) : [],
    );
    return () => {};
  }, [data]);

  useEffect(() => {
    setTotalCount(data ? data?.notificationsList.count : 0);

    setCount(
      ((data?.notificationsList?.groups?.[0] as unknown) as { count: number })
        ?.count,
    );
    setMapped(mapNotificationsList(list));
  }, [list, data, loading]);

  useEffect(() => {
    const subcription = subscribeToMore<{ Notification?: NotificationPayload }>(
      {
        document: SUBSCRIPTION_NOTIFICATION,
        variables: { user, company },
        updateQuery: (
          prev: { notificationsList: NotificationListResponse },
          { subscriptionData },
        ) => {
          if (
            !subscriptionData.data ||
            !prev.notificationsList ||
            !subscriptionData.data.Notification
          )
            return prev;

          const {
            data: {
              Notification: { node, mutation },
            },
          } = subscriptionData;
          const {
            notificationsList: { items: oldList },
          } = prev;

          let newList;

          switch (mutation) {
            case 'update':
              newList = node?.isReaded
                ? oldList.filter((item) => item.id !== node.id)
                : oldList.map((item) => (item.id === node?.id ? node : item));

              break;
            case 'create':
              newList = [node];
              newList.push(...oldList);

              break;
            case 'delete':
              newList = oldList.filter((item) => item.id !== node?.id);
              break;
            default:
              newList = oldList;
              break;
          }

          return {
            ...prev,
            notificationsList: { ...prev.notificationsList, items: newList },
          } as { notificationsList: NotificationListResponse };
        },
      },
    );
    return () => {
      subcription();
    };
  }, [user, company, subscribeToMore, loading]);

  return {
    list,
    today,
    older,
    loading,
    refetch,
    count,
    totalCount,
    readNotificationsAction,
  };
}
