import gql from 'graphql-tag';

export const CREATE_GOAL = gql`
  mutation($data: GoalCreateInput!) {
    goalCreate(data: $data) {
      id
    }
  }
`;

export const COMPANY_TEAMS = gql`
  query($companyId: ID!) {
    company(id: $companyId) {
      companyTeamRelation {
        items {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_GOALS = gql`
  query($filter: GoalFilter, $teamFilter: TeamFilter) {
    goalsList(filter: $filter) {
      items {
        name
        from
        to
        bookedRevenue
        calls
        sprints
        goalTeamRelation(filter: $teamFilter) {
          items {
            name
            teamSprintConfigurationRelation {
              count
              items {
                sprintConfigurationSprintStartedRelation {
                  items {
                    sprintPhoneCallRelation(
                      filter: {
                        OR: [
                          { status: { equals: "answered" } }
                          { status: { equals: "completed" } }
                        ]
                      }
                    ) {
                      count
                    }
                  }
                  count
                }
                sprintConfigLeadSprintConfigRelation {
                  items {
                    lead {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SAVE_END_OF_DAY_REPORT = gql`
  mutation($data: EndOfDayReportCreateInput!) {
    endOfDayReportCreate(data: $data) {
      id
      data
    }
  }
`;

export const FETCH_TEAM_END_OF_DAY_FORMS = gql`
  query($companyId: ID!) {
    endOfDayFormsList(filter: { company: { id: { equals: $companyId } } }) {
      items {
        name
        # team {
        #   name
        # }
      }
    }
  }
`;

export const FETCH_REPORTS_AND_FIELDS = gql`
  query($filter: EndOfDayReportFilter!, $skip: Int!) {
    endOfDayReportsList(filter: $filter, skip: $skip) {
      items {
        data
        createdAt
        createdBy {
          firstName
          lastName
        }
        endOfDayForm {
          name
          fields {
            items {
              name
            }
          }
        }
      }
    }
  }
`;
